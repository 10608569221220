import { RootState } from '@app/store'

export const selectBookingsReports = (store: RootState) =>
  store.pages.reports.bookingsReports.data

export const selectBookingsReportsIsLoading = (store: RootState) =>
  store.pages.reports.bookingsReports.isLoading

export const selectBookingsReportsError = (store: RootState) =>
  store.pages.reports.bookingsReports.error

export const selectBookingsReportsFilters = (store: RootState) =>
  store.pages.reports.bookingsReports.filters
