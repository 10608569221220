import { ActionType } from 'typesafe-actions'
import produce, { castDraft } from 'immer'

import { AirportDetailDtoWTooltips } from '@shared/dto/airports.dto'
import { AirportListActionTypes } from '@app/store/pages/airports/airportList/airportList.constants'
import { ListStore } from '@app/store/types'
import { GetAirportListFilters } from '@app/utils/api/types'
import { DEFAULT_PAGE_LIMIT, OrderDirection } from '@app/constants'
import * as airportListActions from '@app/store/pages/airports/airportList/airportList.actions'

type AirportListAction = ActionType<typeof airportListActions>

export type AirportListStore = ListStore<
  AirportDetailDtoWTooltips,
  GetAirportListFilters
>

const initialAirportListState: AirportListStore = {
  error: null,
  isLoading: false,
  data: null,
  total: null,
  page: 1,
  limit: DEFAULT_PAGE_LIMIT,
  orderBy: 'id',
  orderDirection: OrderDirection.Asc,
  filters: {},
}

const airportListReducer = produce<AirportListStore, [AirportListAction]>(
  (state, action) => {
    switch (action.type) {
      case AirportListActionTypes.GetAirportListDataFirstPage:
        state.isLoading = true
        state.data = null
        state.error = null
        state.total = 0
        state.limit = DEFAULT_PAGE_LIMIT
        state.page = 1

        break

      case AirportListActionTypes.GetAirportListSuccess:
        // @see https://immerjs.github.io/immer/typescript/#cast-utilities
        state.data ??= []
        state.data.push(...castDraft(action.payload.data))

        state.isLoading = false
        state.error = null
        state.total = action.payload.total
        state.page = action.payload.page

        break

      case AirportListActionTypes.GetAirportListFailure:
        state.isLoading = false
        state.error = action.payload

        break

      case AirportListActionTypes.GetAirportListDataNextPage:
        state.isLoading = true

        break

      case AirportListActionTypes.SetAirportListParameters:
        if (action.payload.orderBy) {
          state.orderBy = action.payload.orderBy
        }

        if (action.payload.orderDirection) {
          state.orderDirection = action.payload.orderDirection
        }

        if (action.payload.filters) {
          state.filters = { ...state.filters, ...action.payload.filters }
        }

        break

      default:
        return state
    }
  },
  initialAirportListState,
)

export default airportListReducer
