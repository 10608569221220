// withTranslation

import { call, put, takeLatest, select } from 'redux-saga/effects'
import { navigate } from 'gatsby'
import produce from 'immer'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { UserRegistrationActionTypes } from '@app/store/pages/userRegistration/userRegistration/userRegistration.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { LocalStorageKeys } from '@app/constants'
import { getMyUserInfoAction } from '@app/store/core/userInfo/userInfo.actions'
import { getUserOperatorsAction } from '@app/store/core/userOperators/userOperators.actions'
import * as actions from '@app/store/pages/userRegistration/userRegistration/userRegistration.actions'

import {
  Routes,
  UserRegistrationSteps,
  UserRegistrationTypes,
} from '@shared/enums'

import {
  OperatorRegistrationDto,
  RegistrationBodyDto,
} from '@shared/dto/userRegistration.dto'

import {
  selectCompanyRegistrationData,
  selectOperatorsRegistrationData,
  selectUserRegistrationData,
  selectUserRegistrationToken,
  selectUserRegistrationType,
} from '@app/store/pages/userRegistration/userRegistration/userRegistration.selectors'

// @todo Bump TS version to >= 4.5
// @see https://stackoverflow.com/a/49889856/3210641
type Awaited<T> = T extends PromiseLike<infer U> ? U : T

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiResponse<T extends (...args: any) => any> = Awaited<ReturnType<T>>

// @todo Simplify or remove this type guard
const isOperatorsDataValid = (
  operatorsData: Partial<OperatorRegistrationDto>[],
): operatorsData is OperatorRegistrationDto[] =>
  operatorsData.every(
    (operatorData) =>
      operatorData.name &&
      operatorData.currency_id &&
      operatorData.address_street &&
      operatorData.address_city &&
      operatorData.address_zip_code &&
      operatorData.address_country_id,
  )

function* initRegistrationSaga(
  action: ReturnType<typeof actions.initRegistrationAction>,
) {
  try {
    const { data }: ApiResponse<typeof api.initRegistration> = yield call(
      api.initRegistration,
      {
        user_hash: action.payload,
      },
    )

    yield put(
      actions.setUserRegistrationTokenAction(data.user_registration_token),
    )

    yield put(
      actions.setUserRegistrationTypeAction(data.user_registration_type),
    )

    yield put(
      actions.setUserRegistrationInvitationDataAction(data.invitation_data),
    )

    yield put(actions.setUserRegistrationDataAction(data.user_data))

    yield put(
      actions.setUserRegistrationActiveStepAction(
        UserRegistrationSteps.UserRegistration,
      ),
    )
  } catch (error) {
    console.error(error)

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.initRegistration.notFound')
      404: 'errors.initRegistration.notFound',
      // t('errors.initRegistration.default')
      default: 'errors.initRegistration.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    navigate(Routes.Login)
  }
}

function* updateUserDataSaga() {
  const userRegistrationType = selectUserRegistrationType(yield select())

  if (userRegistrationType === UserRegistrationTypes.CompanyRegistration) {
    yield put(
      actions.setUserRegistrationActiveStepAction(
        UserRegistrationSteps.CompanyRegistration,
      ),
    )
  }
}

function* updateCompanyDataSaga() {
  yield put(
    actions.setUserRegistrationActiveStepAction(
      UserRegistrationSteps.OperatorRegistration,
    ),
  )
}

function* submitRegistrationDataSaga() {
  try {
    const userRegistrationToken = selectUserRegistrationToken(yield select())
    const userRegistrationType = selectUserRegistrationType(yield select())

    const userData = selectUserRegistrationData(yield select())
    const companyData = selectCompanyRegistrationData(yield select())
    const operatorsData = selectOperatorsRegistrationData(yield select())

    if (!userRegistrationToken) {
      throw new Error(
        `User registration token '${userRegistrationToken}' is invalid`,
      )
    }

    if (!userData) {
      throw new Error(`User data is invalid`)
    }

    const nextUserData = produce(userData, (draft) => {
      delete draft.email_address
    })

    const payload = produce<RegistrationBodyDto>(
      {
        user_registration_token: userRegistrationToken,
        user_data: nextUserData,
      },
      (draft) => {
        if (
          userRegistrationType === UserRegistrationTypes.CompanyRegistration
        ) {
          if (!companyData) {
            throw new Error(`Company data is invalid`)
          }

          if (!operatorsData || !isOperatorsDataValid(operatorsData)) {
            throw new Error(`Operators data is invalid`)
          }

          draft.company_data = companyData
          draft.operators_data = operatorsData
        }
      },
    )

    const { data }: ApiResponse<typeof api.submitRegistration> = yield call(
      api.submitRegistration,
      payload,
    )

    yield put(actions.submitRegistrationDataSuccessAction())

    // @todo Merge with Login page logic
    localStorage.setItem(LocalStorageKeys.AuthToken, data.access_token)

    yield put(getMyUserInfoAction())
    yield put(getUserOperatorsAction())

    navigate(Routes.Index)
  } catch (error) {
    console.error(error)

    yield put(actions.submitRegistrationDataFailureAction())

    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.submitRegistration.notFound')
      404: 'errors.submitRegistration.notFound',
      // t('errors.submitRegistration.default')
      default: 'errors.submitRegistration.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }
  }
}

export default function* watchAircraftDetailSaga(): Generator {
  yield takeLatest(
    UserRegistrationActionTypes.InitRegistration,
    initRegistrationSaga,
  )

  yield takeLatest(
    UserRegistrationActionTypes.UpdateUserData,
    updateUserDataSaga,
  )

  yield takeLatest(
    UserRegistrationActionTypes.UpdateCompanyData,
    updateCompanyDataSaga,
  )

  yield takeLatest(
    UserRegistrationActionTypes.SubmitRegistrationData,
    submitRegistrationDataSaga,
  )
}
