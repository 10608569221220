import React, { useState, MouseEvent } from 'react'
import styled from 'styled-components'

import CircularProgress from '@material-ui/core/CircularProgress'

import BaseButton, {
  ButtonProps as BaseButtonProps,
} from '@material-ui/core/Button'
import { prop } from '@app/utils/css'

type Sizes = 'small' | 'normal' | 'large' | 'xlarge'

interface ButtonProps extends Omit<BaseButtonProps, 'onClick' | 'size'> {
  loading?: boolean
  inverted?: boolean
  round?: boolean
  size?: Sizes
  onClick?: (event: MouseEvent) => Promise<void> | void
}

interface StyledButtonProps
  extends Omit<ButtonProps, 'loading' | 'inverted' | 'round'> {
  $inverted: ButtonProps['inverted']
  $round: ButtonProps['round']
  $scale: number
  $fontSize: number
}

const SIZES_TO_SCALE_MAP = {
  small: 1.75,
  normal: 2,
  large: 2.4,
  xlarge: 3,
}

const SIZES_TO_FONT_SIZE = {
  small: 0.75,
  normal: 0.875,
  large: 0.875,
  xlarge: 1,
}

const Button = ({
  children,
  disabled,
  onClick,
  size = 'normal',
  loading = false,
  inverted = false,
  round = false,
  ...props
}: ButtonProps): JSX.Element => {
  const [isInternalLoading, setIsInternalLoading] = useState(false)
  const isButtonLoading = isInternalLoading || loading

  const handleOnClick = async (event: MouseEvent) => {
    const result = onClick?.(event)

    if (result instanceof Promise) {
      setIsInternalLoading(true)

      try {
        await result
      } catch (error) {
        console.error(
          `'onClick' events that return a promise can't throw! Unhandled error:\n\n` +
            error,
        )
      } finally {
        setIsInternalLoading(false)
      }
    }
  }

  return (
    <StyledButton
      $inverted={inverted}
      $round={round}
      $scale={SIZES_TO_SCALE_MAP[size]}
      $fontSize={SIZES_TO_FONT_SIZE[size]}
      disabled={disabled || isButtonLoading}
      variant={inverted ? 'outlined' : 'contained'}
      color="primary"
      onClick={handleOnClick}
      {...props}
    >
      {isButtonLoading && (
        <LoadingWrapper>
          <CircularProgress color="inherit" size={20} />
        </LoadingWrapper>
      )}
      <ChildrenWrapper $loading={isButtonLoading}>{children}</ChildrenWrapper>
    </StyledButton>
  )
}

export const StyledButton = styled(BaseButton)<StyledButtonProps>`
  font-size: ${prop('$fontSize')}rem;
  border-radius: ${({ $round }) => ($round ? '8rem' : '3px')};
  height: ${prop('$scale')}rem;
  text-transform: none;

  background-color: ${({ theme, $inverted }) =>
    $inverted ? theme.palette.common.white : theme.palette.primary.main};

  border: ${({ theme, $inverted }) =>
    $inverted ? `1px solid ${theme.palette.grey[300]}` : 'transparent'};

  &:hover {
    opacity: 0.8;

    background-color: ${({ theme, $inverted }) =>
      $inverted ? theme.palette.common.white : theme.palette.primary.main};
  }

  &:disabled {
    background-color: ${({ theme, $inverted }) =>
      $inverted ? theme.palette.grey[100] : theme.palette.grey[300]};

    border: ${({ theme, $inverted }) =>
      $inverted && `1px solid ${theme.palette.grey[300]}`};

    color: ${({ theme, $inverted }) =>
      $inverted ? theme.palette.grey[400] : theme.palette.common.white};
  }
`

const LoadingWrapper = styled.div`
  position: absolute;
  display: flex;
`

const ChildrenWrapper = styled.div<{ $loading?: boolean }>`
  visibility: ${({ $loading }) => $loading && 'hidden'};
  display: flex;
`

export default Button
