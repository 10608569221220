import { RootState } from '@app/store'

export const selectIsRequestDetailLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isRequestDetailLoading

export const selectRequestDetailData = (store: RootState) =>
  store.pages.requests.requestDetail.requestDetailData

export const selectRequestDetailError = (store: RootState) =>
  store.pages.requests.requestDetail.requestDetailError

export const selectIsRecalculationLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isRecalculationLoading

export const selectIsPatchOfferLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isPatchOfferLoading

export const selectOfferDetailError = (store: RootState) =>
  store.pages.requests.requestDetail.recalculationError

export const selectPatchRequestError = (store: RootState) =>
  store.pages.requests.requestDetail.patchRequestError

export const selectIsPatchRequestLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isPatchRequestLoading

export const selectOfferRecalculationData = (store: RootState) =>
  store.pages.requests.requestDetail.recalculationData?.data

export const selectOfferSchedule = (store: RootState) =>
  store.pages.requests.requestDetail.offerSchedule

export const selectLegEditorSchedule = (store: RootState) =>
  store.pages.requests.requestDetail.legEditorSchedule

export const selectIsLegEditorScheduleLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isLegEditorScheduleLoading

export const selectLegEditorScheduleError = (store: RootState) =>
  store.pages.requests.requestDetail.legEditorScheduleError

export const selectOpenOfferId = (store: RootState) =>
  store.pages.requests.requestDetail.openOfferId

export const selectChatMessages = (store: RootState) =>
  store.pages.requests.requestDetail.chatMessages

export const selectIsChatLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isChatLoading

export const selectChatError = (store: RootState) =>
  store.pages.requests.requestDetail.chatError

export const selectIsPostChatMessageLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isPostChatMessageLoading

export const selectPostChatMessageError = (store: RootState) =>
  store.pages.requests.requestDetail.postChatMessageError

export const selectIsOfferCancellationLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isOfferCancellationLoading

export const selectIsOfferRelatedCustomRoutesDataLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isOfferRelatedCustomRoutesDataLoading

export const selectOfferRelatedCustomRoutesData = (store: RootState) =>
  store.pages.requests.requestDetail.offerRelatedCustomRoutesData

export const selectOfferRelatedCustomRoutesError = (store: RootState) =>
  store.pages.requests.requestDetail.offerRelatedCustomRoutesError

export const selectIsOfferRelatedAirportFeesDataLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isOfferRelatedAirportFeesDataLoading

export const selectOfferRelatedAirportFeesData = (store: RootState) =>
  store.pages.requests.requestDetail.offerRelatedAirportFeesData

export const selectOfferRelatedAirportFeesError = (store: RootState) =>
  store.pages.requests.requestDetail.offerRelatedAirportFeesError

export const selectIsIgnoreLegsFromOptimizationLoading = (store: RootState) =>
  store.pages.requests.requestDetail.isIgnoreLegsFromOptimizationLoading

export const selectIgnoreLegsFromOptimizationData = (store: RootState) =>
  store.pages.requests.requestDetail.ignoreLegsFromOptimizationData?.data

export const selectIgnoreLegsFromOptimizationError = (store: RootState) =>
  store.pages.requests.requestDetail.ignoreLegsFromOptimizationError

export const selectOfferDocumentIds = (store: RootState) =>
  store.pages.requests.requestDetail.offerDocumentIds
