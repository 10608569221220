import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import * as invitationActions from '@app/store/pages/myTeam/invitation/invitation.actions'

import { InvitationsActionTypes } from '@app/store/pages/myTeam/invitation//invitation.constants'

type InvitationAction = ActionType<typeof invitationActions>

export type InvitationStore = {
  isLoading: boolean
  error: unknown
}

const initialInvitationState: InvitationStore = {
  error: null,
  isLoading: false,
}

const InvitationReducer = produce<InvitationStore, [InvitationAction]>(
  (state, action) => {
    switch (action.type) {
      case InvitationsActionTypes.PostInvitation:
        state.isLoading = true
        state.error = null

        break

      case InvitationsActionTypes.PostInvitationSuccess:
        state.isLoading = false
        state.error = null

        break

      case InvitationsActionTypes.PostInvitationFailure:
        state.isLoading = false
        state.error = action.payload

        break

      case InvitationsActionTypes.PostResendInvitation:
        state.isLoading = true
        state.error = null

        break

      case InvitationsActionTypes.PostResendInvitationSuccess:
        state.isLoading = false
        state.error = null

        break

      case InvitationsActionTypes.PostResendInvitationFailure:
        state.isLoading = false
        state.error = action.payload

        break

      default:
        return state
    }
  },
  initialInvitationState,
)

export default InvitationReducer
