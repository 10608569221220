export enum AuthActionTypes {
  Logout = '[core] [auth] Logout]',
  LogoutSuccess = '[core] [auth] [LogoutSuccess]',
  LogoutFailure = '[core] [auth] [LogoutFailure]',

  LogoutFromAllDevices = '[core] [auth] LogoutFromAllDevices]',
  LogoutFromAllDevicesSuccess = '[core] [auth] [LogoutFromAllDevicesSuccess]',
  LogoutFromAllDevicesFailure = '[core] [auth] [LogoutFromAllDevicesFailure]',

  RequestPasswordChange = '[core] [auth] [RequestPasswordChange]',
  RequestPasswordChangeSuccess = '[core] [auth] [RequestPasswordChangeSuccess]',
  RequestPasswordChangeFailure = '[core] [auth] [RequestPasswordChangeFailure]',

  ChangePassword = '[core] [auth] [ChangePassword]',
  ChangePasswordSuccess = '[core] [auth] [ChangePasswordSuccess]',
  ChangePasswordFailure = '[core] [auth] [ChangePasswordFailure]',
}
