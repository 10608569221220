import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import { ContactPersonsActionTypes } from '@app/store/api/contactPersons/contactPersons.constants'

import * as contactPersonsActions from '@app/store/api/contactPersons/contactPersons.actions'

type ContactPersonsAction = ActionType<typeof contactPersonsActions>

export type ContactPersonsState = {
  isPostContactPersonLoading: boolean
  postContactPersonError: unknown
  createdContactPersonId: number | null

  isPatchContactPersonLoading: boolean
  patchContactPersonError: unknown
}

const initialContactPersonsState: ContactPersonsState = {
  isPostContactPersonLoading: false,
  postContactPersonError: null,
  createdContactPersonId: null,

  isPatchContactPersonLoading: false,
  patchContactPersonError: null,
}

const contactPersonsReducer = produce<
  ContactPersonsState,
  [ContactPersonsAction]
>((state, action) => {
  switch (action.type) {
    case ContactPersonsActionTypes.PostContactPerson:
      state.isPostContactPersonLoading = true
      state.postContactPersonError = null
      state.createdContactPersonId = null

      break

    case ContactPersonsActionTypes.PostContactPersonSuccess:
      state.isPostContactPersonLoading = false
      state.createdContactPersonId = action.payload.response.id

      break

    case ContactPersonsActionTypes.PostContactPersonFailure:
      state.isPostContactPersonLoading = false
      state.postContactPersonError = action.payload.error

      break

    case ContactPersonsActionTypes.CancelPostContactPerson:
      state.isPostContactPersonLoading = false

      break

    case ContactPersonsActionTypes.PatchContactPerson:
      state.isPatchContactPersonLoading = true
      state.patchContactPersonError = null

      break

    case ContactPersonsActionTypes.PatchContactPersonSuccess:
      state.isPatchContactPersonLoading = false

      break

    case ContactPersonsActionTypes.PatchContactPersonFailure:
      state.isPatchContactPersonLoading = false
      state.patchContactPersonError = action.payload.error

      break

    default:
      return state
  }
}, initialContactPersonsState)

export default contactPersonsReducer
