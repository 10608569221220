import React, { useState, ReactNode } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import ConfirmationDialog from '@app/components/molecules/ConfirmationDialog/ConfirmationDialog'
import Typography from '@app/components/atoms/Typography/Typography'

import {
  closeConfirmationDialogAction,
  submitConfirmationDialogAction,
} from '@app/store/ui/confirmationDialog/confirmationDialog.actions'

import {
  selectConfirmationDialogProps,
  selectIsConfirmationDialogLoading,
  selectIsConfirmationDialogOpen,
} from '@app/store/ui/confirmationDialog/confirmationDialog.selectors'

interface NotificationsProviderProps {
  children: ReactNode
}

/**
 * @todo Rewrite using https://sweetalert2.github.io/ API instead
 */
const ConfirmationDialogProvider = ({
  children,
}: NotificationsProviderProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isDialogOpen = useSelector(selectIsConfirmationDialogOpen)
  const isLoading = useSelector(selectIsConfirmationDialogLoading)
  const dialogProps = useSelector(selectConfirmationDialogProps)

  const handleSubmit = () => {
    dialogProps?.onSubmit?.()

    dispatch(submitConfirmationDialogAction())

    if (dialogProps?.shouldCloseOnSubmit) {
      dispatch(closeConfirmationDialogAction())
    }
  }

  const handleClose = () => {
    dialogProps?.onClose?.()

    dispatch(closeConfirmationDialogAction())
  }

  const [message, setMessage] = useState(
    t('providers.ConfirmationDialogProvider.defaultMessage'),
  )

  useUpdateEffect(() => {
    // Reset message with delay in order to prevent flash to default message on close
    if (!dialogProps?.i18nextKey) {
      setTimeout(() => {
        setMessage(t('providers.ConfirmationDialogProvider.defaultMessage'))
      }, 250)

      return
    }

    setMessage(t(dialogProps?.i18nextKey))
  }, [dialogProps?.i18nextKey])

  return (
    <>
      {children}
      <ConfirmationDialog
        onClose={handleClose}
        onCancel={handleClose}
        onConfirm={handleSubmit}
        open={isDialogOpen}
        isSubmitting={isLoading}
        dialogContent={
          <DialogContent>
            <Typography>{message}</Typography>
          </DialogContent>
        }
        withCloseIcon
      />
    </>
  )
}

const DialogContent = styled.div`
  padding: 2rem 0;
`

export default ConfirmationDialogProvider
