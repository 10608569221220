import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import * as createAircraftActions from '@app/store/pages/createAircraft/createAircraft/createAircraft.actions'
import { CreateAircraftActionTypes } from '@app/store/pages/createAircraft/createAircraft/createAircraft.constants'
import { BaseDataStore } from '@app/store/types'
import { PartialAircraftDto } from '@shared/dto/aircraft.dto'

type CreateAircraftAction = ActionType<typeof createAircraftActions>

export type CreateAircraftStore = BaseDataStore<PartialAircraftDto>

const initialCreateAircraftState: CreateAircraftStore = {
  error: null,
  isLoading: false,
  data: null,
}

const createAircraftReducer = produce<
  CreateAircraftStore,
  [CreateAircraftAction]
>((state, action) => {
  switch (action.type) {
    case CreateAircraftActionTypes.StorePartialAircraftData:
      state.data = { ...state.data, ...action.payload }

      break

    case CreateAircraftActionTypes.PostAircraft:
      state.isLoading = true
      state.error = null

      break

    case CreateAircraftActionTypes.PostAircraftSuccess:
      state.isLoading = false
      state.error = null
      state.data = null

      break

    case CreateAircraftActionTypes.PostAircraftFailure:
      state.isLoading = false
      state.error = action.payload

      break

    case CreateAircraftActionTypes.ClearCreateAircraftStore:
      return initialCreateAircraftState

    default:
      return state
  }
}, initialCreateAircraftState)

export default createAircraftReducer
