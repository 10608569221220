/**
 * @see https://lifesaver.codes/answer/waiting-for-a-saga-to-complete-697
 */

import { Middleware, Action, AnyAction } from 'redux'

import { RootState } from '@app/store'

export type Deferrable = {
  resolve?: (value?: unknown) => void
  reject?: (error?: unknown) => void
}

export type DeferrableAction<T> = T & {
  meta: { promise: Required<Deferrable> }
}

declare module 'redux' {
  export interface Dispatch<A extends Action = AnyAction> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T extends A>(action: T): Promise<any>
  }
}

const createExposedPromise = () => {
  const deferred: Deferrable = {}

  const promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })

  return [promise, deferred]
}

export const deferredMiddleware: Middleware<unknown, RootState> =
  () => (next) => (action) => {
    if (!action.meta?.deferred) {
      return next(action)
    }

    const [promise, deferred] = createExposedPromise()

    action.meta.promise = deferred

    next(action)

    return promise
  }
