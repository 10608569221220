import { action } from 'typesafe-actions'

import { RebookingActionTypes } from '@app/store/pages/requests/rebooking/rebooking.constants'
import { LegComputationRequest } from '@shared/interfaces/Computation'

export const setIsRebookingDialogOpenAction = (
  nextIsRebookingDialogOpen: boolean,
) =>
  action(
    RebookingActionTypes.SetIsRebookingDialogOpen,
    nextIsRebookingDialogOpen,
  )

export const initRebookingAction = (
  offerId: number,
  rebookingRequests: LegComputationRequest[],
) =>
  action(RebookingActionTypes.InitRebooking, {
    offerId,
    rebookingRequests,
  })

export const rebookOfferSuccessAction = () =>
  action(RebookingActionTypes.OfferRebookingSuccess)

export const setRebookingFailureAction = (error: unknown) =>
  action(RebookingActionTypes.OfferRebookingFailure, error)

export const resetRebookingStateAction = () =>
  action(RebookingActionTypes.ResetRebookingState)
