import { ActionType } from 'typesafe-actions'
import produce, { castDraft } from 'immer'

import { ClientDetailDto } from '@shared/dto/clients.dto'
import { ClientsActionTypes } from '@app/store/pages/clients/clientsList/clientsList.constants'
import { ListStore } from '@app/store/types'
import { GetClientsFilters } from '@app/utils/api/types'
import * as ClientsActions from '@app/store/pages/clients/clientsList/clientsList.actions'

import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_PAGE_LIMIT,
} from '@app/constants'

type ClientsAction = ActionType<typeof ClientsActions>

export type ClientsStore = ListStore<ClientDetailDto, GetClientsFilters> & {
  isReloading: boolean

  isDeleteClientLoading: boolean
  deleteClientError: unknown
}

const initialClientsState: ClientsStore = {
  error: null,
  isLoading: false,
  isReloading: false,
  data: null,
  total: null,
  page: 1,
  limit: DEFAULT_PAGE_LIMIT,
  orderBy: DEFAULT_ORDER_BY,
  orderDirection: DEFAULT_ORDER_DIRECTION,
  filters: {},

  isDeleteClientLoading: false,
  deleteClientError: null,
}

const ClientsReducer = produce<ClientsStore, [ClientsAction]>(
  (state, action) => {
    switch (action.type) {
      case ClientsActionTypes.GetClientsDataFirstPage:
        state.isLoading = true
        state.data = null
        state.error = null
        state.total = 0
        state.limit = DEFAULT_PAGE_LIMIT
        state.page = 1

        break

      case ClientsActionTypes.GetClientsSuccess:
        // @see https://immerjs.github.io/immer/typescript/#cast-utilities
        state.data ??= []
        state.data.push(...castDraft(action.payload.data))

        state.isLoading = false
        state.error = null
        state.total = action.payload.total
        state.page = action.payload.page

        break

      case ClientsActionTypes.GetClientsFailure:
        state.isLoading = false
        state.error = action.payload

        break

      case ClientsActionTypes.GetClientsDataNextPage:
        state.isLoading = true

        break

      case ClientsActionTypes.ResetClientsFilters:
        state.filters = {}

        break

      case ClientsActionTypes.SetClientsSort:
        if (action.payload.orderBy) {
          state.orderBy = action.payload.orderBy
        }

        if (action.payload.orderDirection) {
          state.orderDirection = action.payload.orderDirection
        }

        break

      case ClientsActionTypes.SetClientsFilters:
        if (action.payload.filters) {
          state.filters = { ...state.filters, ...action.payload.filters }
        }

        break

      case ClientsActionTypes.ReloadClients:
        state.isReloading = true

        break

      case ClientsActionTypes.ReloadClientsSuccess:
        state.isReloading = false
        state.error = null
        state.data = action.payload.data
        state.total = action.payload.total

        break

      case ClientsActionTypes.ResetClients:
        state.error = initialClientsState.error
        state.isLoading = initialClientsState.isLoading
        state.data = initialClientsState.data
        state.total = initialClientsState.total
        state.page = initialClientsState.page
        state.limit = initialClientsState.limit
        state.orderBy = initialClientsState.orderBy
        state.orderDirection = initialClientsState.orderDirection
        state.filters = initialClientsState.filters

        break

      case ClientsActionTypes.DeleteClient:
        state.isDeleteClientLoading = true
        state.deleteClientError = null

        break

      case ClientsActionTypes.DeleteClientSuccess:
        state.isDeleteClientLoading = false

        break

      case ClientsActionTypes.DeleteClientFailure:
        state.isDeleteClientLoading = false
        state.deleteClientError = action.payload.error

        break

      case ClientsActionTypes.CancelClientDelete:
        state.isDeleteClientLoading = false

        break

      default:
        return state
    }
  },
  initialClientsState,
)

export default ClientsReducer
