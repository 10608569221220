import axios from 'axios'

interface ErrorMessages {
  [key: number]: string
  default: string
}

export const getErrorMessage = (
  error: unknown,
  errorMessages: ErrorMessages,
): string | null => {
  if (!error) {
    return null
  }

  if (!axios.isAxiosError(error) || !error?.response?.status) {
    return errorMessages.default
  }

  return errorMessages[error.response.status] ?? errorMessages.default
}
