export enum MyTeamListActionTypes {
  GetMyTeamListData = '[pages] [myTeam] [myTeamList] [GetMyTeamListData]',
  GetMyTeamListSuccess = '[pages] [myTeam] [myTeamList] [GetMyTeamListSuccess]',
  GetMyTeamListFailure = '[pages] [myTeam] [myTeamList] [GetMyTeamListFailure]',

  GetUserData = '[pages] [myTeam] [myTeamList] [GetUserData]',
  GetUserDataSuccess = '[pages] [myTeam] [myTeamList] [GetUserDataSuccess]',
  GetUserDataFailure = '[pages] [myTeam] [myTeamList] [GetUserDataFailure]',

  PatchUserData = '[pages] [myTeam] [myTeamList] [PatchUserData]',
  PatchUserDataSuccess = '[pages] [myTeam] [myTeamList] [PatchUserDataSuccess]',
  PatchUserDataFailure = '[pages] [myTeam] [myTeamList] [PatchUserDataFailure]',

  StartSetMyTeamListSort = '[pages] [myTeam] [myTeamList] [StartSetMyTeamListSort]',
  SetMyTeamListParameters = '[pages] [myTeam] [myTeamList] [SetMyTeamListParameters]',
}
