export enum UserRegistrationActionTypes {
  InitRegistration = '[pages] [userRegistration] [userRegistration] [InitRegistration]',

  SetUserRegistrationToken = '[pages] [userRegistration] [userRegistration] [SetUserRegistrationToken]',
  SetUserRegistrationType = '[pages] [userRegistration] [userRegistration] [SetUserRegistrationType]',
  SetUserRegistrationInvitationData = '[pages] [userRegistration] [userRegistration] [SetUserRegistrationInvitationData]',
  SetUserRegistrationData = '[pages] [userRegistration] [userRegistration] [SetUserRegistrationData]',

  UpdateUserData = '[pages] [userRegistration] [userRegistration] [UpdateUserData]',
  AddOperator = '[pages] [userRegistration] [userRegistration] [AddOperator]',
  UpdateOperatorData = '[pages] [userRegistration] [userRegistration] [UpdateOperatorData]',
  UpdateCompanyData = '[pages] [userRegistration] [userRegistration] [UpdateCompanyData]',

  SetUserRegistrationActiveStep = '[pages] [userRegistration] [userRegistration] [SetUserRegistrationActiveStep]',
  ResetRegistration = '[pages] [userRegistration] [userRegistration] [ResetRegistration]',

  SubmitRegistrationData = '[pages] [userRegistration] [userRegistration] [SubmitRegistrationData]',
  SubmitRegistrationDataSuccess = '[pages] [userRegistration] [userRegistration] [SubmitRegistrationDataSuccess]',
  SubmitRegistrationDataFailure = '[pages] [userRegistration] [userRegistration] [SubmitRegistrationDataFailure]',
}
