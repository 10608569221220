import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { AircraftDetailActionTypes } from '@app/store/pages/aircraftDetail/aircraftDetail/aircraftDetail.constants'
import { BaseDataStore } from '@app/store/types'
import * as aircraftDetailActions from '@app/store/pages/aircraftDetail/aircraftDetail/aircraftDetail.actions'

type AircraftDetailAction = ActionType<typeof aircraftDetailActions>

export type AircraftDetailStore = BaseDataStore<AircraftDetailDto>

const initialAircraftDetailState: AircraftDetailStore = {
  error: null,
  isLoading: false,
  data: null,
}

const aircraftDetailReducer = produce<
  AircraftDetailStore,
  [AircraftDetailAction]
>((state, action) => {
  switch (action.type) {
    case AircraftDetailActionTypes.GetAircraftDetail:
      state.isLoading = true
      state.data = null
      state.error = null

      break

    case AircraftDetailActionTypes.GetAircraftDetailSuccess:
      state.isLoading = false
      state.data = action.payload
      state.error = null

      break

    case AircraftDetailActionTypes.GetAircraftDetailFailure:
      state.isLoading = false
      state.error = action.payload
      state.data = null

      break

    case AircraftDetailActionTypes.PatchAircraft:
      state.isLoading = true

      break

    case AircraftDetailActionTypes.PatchAircraftFailure:
      state.isLoading = false
      state.error = action.payload

      break

    default:
      return state
  }
}, initialAircraftDetailState)

export default aircraftDetailReducer
