import React, { ReactNode, SyntheticEvent } from 'react'
import styled from 'styled-components'

interface SimpleButtonProps {
  children: ReactNode
  onClick: (event: SyntheticEvent) => void
}

const SimpleButton = (props: SimpleButtonProps): JSX.Element => {
  return <StyledButton {...props} />
}

const StyledButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.secondary};
  font-size: 1rem;
  font-weight: 600;
  padding: 0 1rem;
  text-decoration: underline;
`

export default SimpleButton
