import { RootState } from '@app/store'

export const selectCustomRoutesLimit = (store: RootState) =>
  store.api.customRoutes.limit

export const selectCustomRoutesPage = (store: RootState) =>
  store.api.customRoutes.page

export const selectCustomRoutesFilters = (store: RootState) =>
  store.api.customRoutes.filters

export const selectCustomRoutesOrderBy = (store: RootState) =>
  store.api.customRoutes.orderBy

export const selectCustomRoutesData = (store: RootState) =>
  store.api.customRoutes.data

export const selectCustomRoutesTotal = (store: RootState) =>
  store.api.customRoutes.total

export const selectCustomRoutesIsLoading = (store: RootState) =>
  store.api.customRoutes.isLoading

export const selectCustomRoutesError = (store: RootState) =>
  store.api.customRoutes.error

export const selectCustomRoutesOrderDirection = (store: RootState) =>
  store.api.customRoutes.orderDirection

export const selectIsPostCustomRouteLoading = (store: RootState) =>
  store.api.customRoutes.isPostLoading

export const selectIsPatchCustomRouteLoading = (store: RootState) =>
  store.api.customRoutes.isPatchLoading

export const selectIsDeleteCustomRouteLoading = (store: RootState) =>
  store.api.customRoutes.isDeleteLoading
