import React from 'react'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'

import LoadingSpinner from '@app/components/atoms/LoadingSpinner/LoadingSpinner'
import Typography from '@app/components/atoms/Typography/Typography'
import Logo from '@app/components/atoms/Logo/Logo'
import SimpleButton from '@app/components/atoms/SimpleButton/SimpleButton'

export type SidebarAction<T = string> = {
  id: T
  label: string
}

interface CredentialsLayoutProps<SidebarActionId> {
  onSidebarActionButtonClick?: (item: SidebarAction<SidebarActionId>) => void
  sidebarTitle?: string
  sidebarContent?: JSX.Element
  sidebarActions?: SidebarAction<SidebarActionId>[]
  mainTitle?: string
  mainContent?: JSX.Element
  loading?: boolean
}

const CredentialsLayout = <SidebarActionId extends string = string>({
  sidebarTitle,
  sidebarContent,
  sidebarActions,
  mainTitle,
  mainContent,
  onSidebarActionButtonClick,
  loading = false,
}: CredentialsLayoutProps<SidebarActionId>): JSX.Element => {
  return (
    <ContentContainer>
      <Box
        display="flex"
        flexDirection="column"
        width={1 / 3}
        minWidth="10rem"
        maxWidth="25rem"
        minHeight="100vh"
        pr={3}
        pt={5}
        pl={9}
        bgcolor="rgba(0,50,137,0.07)"
        zIndex={1}
      >
        <StyledLoadingSpinner loading={loading}>
          <Logo width="62px" />
        </StyledLoadingSpinner>

        <Box mb={2}>
          <Typography variant="mainHeading">{sidebarTitle}</Typography>
        </Box>

        {sidebarContent}

        <Box>
          {sidebarActions?.map((sidebarAction, index) => (
            <Box key={index} display="inline-block" pt={2}>
              <StyledSimpleButton
                $isLast={sidebarActions.length === index + 1}
                $isFirst={index === 0}
                onClick={() => onSidebarActionButtonClick?.(sidebarAction)}
              >
                {sidebarAction.label}
              </StyledSimpleButton>
            </Box>
          ))}
        </Box>
      </Box>

      <Box flex="1" height={1} px={9} py={9} zIndex={1}>
        {mainTitle && (
          <Box display="flex" alignItems="center">
            <Typography variant="mainHeading">{mainTitle}</Typography>
            <HorizontalRule />
          </Box>
        )}

        {mainContent}
      </Box>

      <WingImage src="/images/plane.png" />
    </ContentContainer>
  )
}

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin-right: auto;
`

const ContentContainer = styled.div`
  display: flex;
  background: url(/images/sky.jpg) center/cover no-repeat;
  min-height: 100vh;
  min-width: 100vw;
`

// TODO Create SimpleButton component
const StyledSimpleButton = styled(SimpleButton)<{
  $isLast: boolean
  $isFirst: boolean
}>`
  padding-left: ${({ $isFirst }) => $isFirst && `0`};

  border-right: ${({ $isLast }) =>
    !$isLast && css`1px solid ${({ theme }) => theme.palette.grey[300]}`};
`

const HorizontalRule = styled.hr`
  height: 1px;
  border: 1px solid #e0e0e0;
  flex: 1;
  margin: 0 10% 0 2rem;
`

const WingImage = styled.img`
  bottom: 0;
  position: fixed;
  right: 0;
  width: 50rem;
  z-index: 0;
`

export default CredentialsLayout
