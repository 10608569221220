import React, { Component, ReactElement } from 'react'

import Error from '@app/components/molecules/Error/Error'

interface GlobalErrorBoundaryProps {
  children: ReactElement
}

interface GlobalErrorBoundaryState {
  hasError: boolean
}

class GlobalErrorBoundary extends Component<
  GlobalErrorBoundaryProps,
  GlobalErrorBoundaryState
> {
  constructor(props: GlobalErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: unknown) {
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      return <Error />
    }

    return this.props.children
  }
}

export default GlobalErrorBoundary
