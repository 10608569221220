import { action } from 'typesafe-actions'

import { InvitationsActionTypes } from '@app/store/pages/myTeam/invitation/invitation.constants'
import { InvitationDto } from '@app/utils/api/types'

export const postInvitationAction = (data: InvitationDto) =>
  action(InvitationsActionTypes.PostInvitation, data)

export const postInvitationSuccessAction = () =>
  action(InvitationsActionTypes.PostInvitationSuccess)

export const postInvitationFailureAction = (error: unknown) =>
  action(InvitationsActionTypes.PostInvitationFailure, error)

export const postResendInvitationAction = (
  operatorId: number,
  userId: number,
) => action(InvitationsActionTypes.PostResendInvitation, { operatorId, userId })

export const postResendInvitationSuccessAction = () =>
  action(InvitationsActionTypes.PostResendInvitationSuccess)

export const postResendInvitationFailureAction = (error: unknown) =>
  action(InvitationsActionTypes.PostResendInvitationFailure, error)
