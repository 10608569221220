export enum ClientsActionTypes {
  GetClientsDataFirstPage = '[pages] [clients] [clientsList] [GetClientsDataFirstPage]',
  GetClientsDataNextPage = '[pages] [clients] [clientsList] [GetClientsDataNextPage]',
  GetClientsSuccess = '[pages] [clients] [clientsList] [GetClientsSuccess]',
  GetClientsFailure = '[pages] [clients] [clientsList] [GetClientsFailure]',

  SetClientsSort = '[pages] [clients] [clientsList] [SetClientsSort]',
  SetClientsFilters = '[pages] [clients] [clientsList] [SetClientsFilters]',

  ResetClientsFilters = '[pages] [clients] [clientsList] [ResetClientsFilters]',
  ResetClients = '[pages] [clients] [clientsList] [ResetClients]',

  ReloadClients = '[pages] [clients] [clientsList] [ReloadClients]',
  ReloadClientsSuccess = '[pages] [clients] [clientsList] [ReloadClientsSuccess]',

  DeleteClient = '[pages] [clients] [clientsList] [DeleteClient]',
  DeleteClientSuccess = '[pages] [clients] [clientsList] [DeleteClientSuccess]',
  DeleteClientFailure = '[pages] [clients] [clientsList] [DeleteClientFailure]',
  CancelClientDelete = '[pages] [clients] [clientsList] [CancelClientDelete]',
}
