import { action } from 'typesafe-actions'

import { OperatorSettingsActionTypes } from '@app/store/pages/settings/operatorSettings/operatorSettings.constants'
import { OperatorDto } from '@shared/dto/operator.dto'

export const patchOperatorAction = (
  id: number,
  partialOperatorDto: Partial<OperatorDto>,
) =>
  action(OperatorSettingsActionTypes.PatchOperator, { id, partialOperatorDto })

export const patchOperatorSuccessAction = (operator: OperatorDto) =>
  action(OperatorSettingsActionTypes.PatchOperatorSuccess, { operator })

export const patchOperatorFailureAction = (error: unknown) =>
  action(OperatorSettingsActionTypes.PatchOperatorFailure, { error })

export const postOperatorAction = (postOperatorDto: Partial<OperatorDto>) =>
  action(OperatorSettingsActionTypes.PostOperator, { postOperatorDto })

export const postOperatorSuccessAction = ({ id }: { id: number }) =>
  action(OperatorSettingsActionTypes.PostOperatorSuccess, { id })

export const postOperatorFailureAction = (error: unknown) =>
  action(OperatorSettingsActionTypes.PostOperatorFailure, { error })
