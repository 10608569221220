export enum AirportListActionTypes {
  GetAirportListDataFirstPage = '[airports] [airportList] [GetAirportListDataFirstPage]',
  GetAirportListDataNextPage = '[airports] [airportList] [GetAirportListDataNextPage]',
  GetAirportListSuccess = '[airports] [airportList] [GetAirportListSuccess]',
  GetAirportListFailure = '[airports] [airportList] [GetAirportListFailure]',

  StartSetAirportListSort = '[airports] [airportList] [StartSetAirportListSort]',
  StartSetAirportListFilters = '[airports] [airportList] [StartSetAirportListFilters]',
  SetAirportListParameters = '[airports] [airportList] [SetAirportListParameters]',
}
