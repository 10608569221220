import { RootState } from '@app/store'

export const selectScheduleLimit = (store: RootState) =>
  store.pages.schedule.scheduleList.limit

export const selectSchedulePage = (store: RootState) =>
  store.pages.schedule.scheduleList.page

export const selectScheduleFilters = (store: RootState) =>
  store.pages.schedule.scheduleList.filters

export const selectScheduleOrderBy = (store: RootState) =>
  store.pages.schedule.scheduleList.orderBy

export const selectScheduleData = (store: RootState) =>
  store.pages.schedule.scheduleList.schedule

export const selectScheduleAircraft = (store: RootState) =>
  store.pages.schedule.scheduleList.aircraft

export const selectFinalPriceAndTotalProfit = (store: RootState) =>
  store.pages.schedule.scheduleList.finalPriceAndTotalProfit

export const selectScheduleTotal = (store: RootState) =>
  store.pages.schedule.scheduleList.total

export const selectScheduleIsLoading = (store: RootState) =>
  store.pages.schedule.scheduleList.isLoading

export const selectScheduleError = (store: RootState) =>
  store.pages.schedule.scheduleList.error

export const selectScheduleOrderDirection = (store: RootState) =>
  store.pages.schedule.scheduleList.orderDirection
