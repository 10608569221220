import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import * as scheduleManagementActions from '@app/store/pages/schedule/scheduleManagement/scheduleManagement.actions'
import { ScheduleManagementActionTypes } from '@app/store/pages/schedule/scheduleManagement/scheduleManagement.constants'

type ScheduleManagementAction = ActionType<typeof scheduleManagementActions>

export type ScheduleManagementStore = {
  isPostOutageLoading: boolean
  postOutageError: unknown
  isPatchOutageLoading: boolean
  patchOutageError: unknown
  isDeleteOutageLoading: boolean
  deleteOutageError: unknown
  isPatchMarketplaceExtensionLoading: boolean
  patchMarketplaceExtensionError: unknown
  isDeleteMarketplaceExtensionLoading: boolean
  deleteMarketplaceExtensionError: unknown
}

const initialScheduleManagementState: ScheduleManagementStore = {
  isPostOutageLoading: false,
  postOutageError: null,
  isPatchOutageLoading: false,
  patchOutageError: null,
  isDeleteOutageLoading: false,
  deleteOutageError: null,
  isPatchMarketplaceExtensionLoading: false,
  patchMarketplaceExtensionError: null,
  isDeleteMarketplaceExtensionLoading: false,
  deleteMarketplaceExtensionError: null,
}

const scheduleManagementReducer = produce<
  ScheduleManagementStore,
  [ScheduleManagementAction]
>((state, action) => {
  switch (action.type) {
    case ScheduleManagementActionTypes.PostOutage:
      state.isPostOutageLoading = true
      state.postOutageError = null

      break

    case ScheduleManagementActionTypes.PostOutageSuccess:
      state.isPostOutageLoading = false
      state.postOutageError = null

      break

    case ScheduleManagementActionTypes.PostOutageFailure:
      state.isPostOutageLoading = false
      state.postOutageError = action.payload

      break

    case ScheduleManagementActionTypes.PatchOutage:
      state.isPatchOutageLoading = true
      state.patchOutageError = null

      break

    case ScheduleManagementActionTypes.PatchOutageSuccess:
      state.isPatchOutageLoading = false
      state.patchOutageError = null

      break

    case ScheduleManagementActionTypes.PatchOutageFailure:
      state.isPatchOutageLoading = false
      state.patchOutageError = action.payload

      break

    case ScheduleManagementActionTypes.DeleteOutage:
      state.isDeleteOutageLoading = true
      state.deleteOutageError = null

      break

    case ScheduleManagementActionTypes.DeleteOutageSuccess:
      state.isDeleteOutageLoading = false
      state.deleteOutageError = null

      break

    case ScheduleManagementActionTypes.DeleteOutageFailure:
      state.isDeleteOutageLoading = false
      state.deleteOutageError = action.payload

      break

    case ScheduleManagementActionTypes.PatchMarketplaceExtension:
      state.isPatchMarketplaceExtensionLoading = true
      state.patchMarketplaceExtensionError = null

      break

    case ScheduleManagementActionTypes.PatchMarketplaceExtensionSuccess:
      state.isPatchMarketplaceExtensionLoading = false
      state.patchMarketplaceExtensionError = null

      break

    case ScheduleManagementActionTypes.PatchMarketplaceExtensionFailure:
      state.isPatchMarketplaceExtensionLoading = false
      state.patchMarketplaceExtensionError = action.payload

      break

    case ScheduleManagementActionTypes.DeleteMarketplaceExtension:
      state.isDeleteMarketplaceExtensionLoading = true
      state.deleteMarketplaceExtensionError = null

      break

    case ScheduleManagementActionTypes.DeleteMarketplaceExtensionSuccess:
      state.isDeleteMarketplaceExtensionLoading = false
      state.deleteMarketplaceExtensionError = null

      break

    case ScheduleManagementActionTypes.DeleteMarketplaceExtensionFailure:
      state.isDeleteMarketplaceExtensionLoading = false
      state.deleteMarketplaceExtensionError = action.payload

      break

    case ScheduleManagementActionTypes.ClearScheduleManagementStore:
      return state

    default:
      return state
  }
}, initialScheduleManagementState)

export default scheduleManagementReducer
