import { ActionType } from 'typesafe-actions'
import produce, { castDraft } from 'immer'

import { PartialRequestDto } from '@shared/dto/requests.dto'
import { RequestsListActionTypes } from '@app/store/pages/requests/requestList/requestList.constants'
import { ListStore } from '@app/store/types'
import { GetRequestsListFilters } from '@app/utils/api/types'
import { RequestPageVariants } from '@app/components/pages/Requests/Requests'
import * as RequestsListActions from '@app/store/pages/requests/requestList/requestList.actions'

import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_PAGE_LIMIT,
} from '@app/constants'

type RequestsListAction = ActionType<typeof RequestsListActions>

export interface RequestsListStore
  extends ListStore<PartialRequestDto, GetRequestsListFilters> {
  isReloading: boolean
  isPolling: boolean
  openRequestId: number | null
  variant: RequestPageVariants | null
  openRequestPosition: number | null
  openRequestData: PartialRequestDto | null
}

const initialRequestsListState: RequestsListStore = {
  error: null,
  isLoading: false,
  data: null,
  total: null,
  page: 1,
  limit: DEFAULT_PAGE_LIMIT,
  orderBy: DEFAULT_ORDER_BY,
  orderDirection: DEFAULT_ORDER_DIRECTION,
  filters: {},
  isReloading: false,
  isPolling: false,
  openRequestId: null,
  openRequestPosition: null,
  openRequestData: null,
  variant: null,
}

const RequestsListReducer = produce<RequestsListStore, [RequestsListAction]>(
  (state, action) => {
    function hasOpenedRequestData() {
      return (
        !!state.openRequestId &&
        typeof state.openRequestPosition === 'number' &&
        !!state.openRequestData
      )
    }

    // if request is opened - keep it in list even if flags has changed
    function addOpenedItemToData(data: PartialRequestDto[]) {
      let newData = [...data]
      const addOpenedRequest = hasOpenedRequestData()

      if (addOpenedRequest) {
        const isInData = newData.find((r) => r.id === state.openRequestId)

        newData = isInData
          ? newData.filter((r) => r.id !== state.openRequestId)
          : newData

        newData.splice(state.openRequestPosition!, 0, {
          ...{ ...state.openRequestData! },
          unread_messages_count: 0,
          ...(isInData ?? {}),
        })
      }

      return newData
    }

    switch (action.type) {
      case RequestsListActionTypes.ReloadRequestsListSuccess: {
        // TODO naming (isReloading)
        state.isReloading = false
        state.error = null
        state.data = addOpenedItemToData(action.payload.data)
        state.total = action.payload.total

        break
      }

      case RequestsListActionTypes.ResetRequestList:
        state.error = initialRequestsListState.error
        state.isLoading = initialRequestsListState.isLoading
        state.data = initialRequestsListState.data
        state.total = initialRequestsListState.total
        state.page = initialRequestsListState.page
        state.limit = initialRequestsListState.limit
        state.orderBy = initialRequestsListState.orderBy
        state.orderDirection = initialRequestsListState.orderDirection
        state.filters = initialRequestsListState.filters

        break

      case RequestsListActionTypes.GetRequestsListDataFirstPage:
        state.isLoading = true
        state.data = null
        state.error = null
        state.total = 0
        state.limit = DEFAULT_PAGE_LIMIT
        state.page = 1

        break

      case RequestsListActionTypes.GetRequestsListSuccess:
        // @see https://immerjs.github.io/immer/typescript/#cast-utilities
        state.data ??= []
        // remove opened request from new data
        const shouldRemoveOpenedRequest = hasOpenedRequestData()
        state.data.push(
          ...castDraft(action.payload.data).filter((r) =>
            shouldRemoveOpenedRequest ? r.id !== state.openRequestId : true,
          ),
        )

        state.isLoading = false
        state.error = null
        state.total = action.payload.total
        state.page = action.payload.page

        break
      case RequestsListActionTypes.GetRequestsListFailure:
        state.isLoading = false
        state.error = action.payload

        break

      case RequestsListActionTypes.GetRequestsListDataNextPage:
        state.isLoading = true

        break

      case RequestsListActionTypes.ResetRequestsListFilters:
        state.filters = {}

        break

      case RequestsListActionTypes.SetRequestsListSort:
        if (action.payload.orderBy) {
          state.orderBy = action.payload.orderBy
        }

        if (action.payload.orderDirection) {
          state.orderDirection = action.payload.orderDirection
        }

        break

      case RequestsListActionTypes.SetRequestsListFilters:
        if (action.payload.filters) {
          state.filters = {
            ...state.filters,
            ...action.payload.filters,
            reserved: action.payload.filters.reserved || undefined,
          }
        }

        break

      case RequestsListActionTypes.ReloadRequestsList:
        state.isReloading = true

        break

      case RequestsListActionTypes.StartRequestsListPolling:
        state.isPolling = true

        break

      case RequestsListActionTypes.StopRequestsListPolling:
        state.isPolling = false

        break

      case RequestsListActionTypes.SetOpenRequestId:
        state.openRequestId = action.payload
        // keep open request data if list change
        const index = state.data?.findIndex((r) => r.id === action.payload)
        const data = state.data?.find((r) => r.id === action.payload)
        state.openRequestPosition = typeof index === 'number' ? index : null
        state.openRequestData = !!data ? { ...data } : null

        break

      case RequestsListActionTypes.SetRequestPageVariant:
        state.variant = action.payload

        break

      default:
        return state
    }
  },
  initialRequestsListState,
)

export default RequestsListReducer
