export enum ScheduleManagementActionTypes {
  PostOutage = '[pages] [schedule] [scheduleManagement] [PostOutage]',
  PostOutageSuccess = '[pages] [schedule] [scheduleManagement] [PostOutageSuccess]',
  PostOutageFailure = '[pages] [schedule] [scheduleManagement] [PostOutageFailure]',

  PatchOutage = '[pages] [schedule] [scheduleManagement] [PatchOutage]',
  PatchOutageSuccess = '[pages] [schedule] [scheduleManagement] [PatchOutageSuccess]',
  PatchOutageFailure = '[pages] [schedule] [scheduleManagement] [PatchOutageFailure]',

  DeleteOutage = '[pages] [schedule] [scheduleManagement] [DeleteOutage]',
  DeleteOutageSuccess = '[pages] [schedule] [scheduleManagement] [DeleteOutageSuccess]',
  DeleteOutageFailure = '[pages] [schedule] [scheduleManagement] [DeleteOutageFailure]',

  PatchMarketplaceExtension = '[pages] [schedule] [scheduleManagement] [PatchMarketplaceExtension]',
  PatchMarketplaceExtensionSuccess = '[pages] [schedule] [scheduleManagement] [PatchMarketplaceExtensionSuccess]',
  PatchMarketplaceExtensionFailure = '[pages] [schedule] [scheduleManagement] [PatchMarketplaceExtensionFailure]',

  DeleteMarketplaceExtension = '[pages] [schedule] [scheduleManagement] [DeleteMarketplaceExtension]',
  DeleteMarketplaceExtensionSuccess = '[pages] [schedule] [scheduleManagement] [DeleteMarketplaceExtensionSuccess]',
  DeleteMarketplaceExtensionFailure = '[pages] [schedule] [scheduleManagement] [DeleteMarketplaceExtensionFailure]',

  ClearScheduleManagementStore = '[pages] [schedule] [scheduleManagement] [ClearScheduleManagementStore]',
}
