import { action } from 'typesafe-actions'

import { Notification } from '@app/store/ui/notifications/notifications.types'
import { NotificationActionTypes } from '@app/store/ui/notifications/notifications.constants'

export const addNotificationAction = (message: Notification) =>
  action(NotificationActionTypes.AddNotification, message)

export const removeNotificationAction = (id: string) =>
  action(NotificationActionTypes.RemoveNotification, id)
