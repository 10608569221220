import { RootState } from '@app/store'

export const selectAircraftListLimit = (store: RootState) =>
  store.pages.fleet.aircraftList.limit

export const selectAircraftListPage = (store: RootState) =>
  store.pages.fleet.aircraftList.page

export const selectAircraftListFilters = (store: RootState) =>
  store.pages.fleet.aircraftList.filters

export const selectAircraftListOrderBy = (store: RootState) =>
  store.pages.fleet.aircraftList.orderBy

export const selectAircraftListData = (store: RootState) =>
  store.pages.fleet.aircraftList.data

export const selectAircraftListTotal = (store: RootState) =>
  store.pages.fleet.aircraftList.total

export const selectAircraftListIsLoading = (store: RootState) =>
  store.pages.fleet.aircraftList.isLoading

export const selectAircraftListError = (store: RootState) =>
  store.pages.fleet.aircraftList.error

export const selectAircraftListOrderDirection = (store: RootState) =>
  store.pages.fleet.aircraftList.orderDirection
