export enum CustomRoutesActionTypes {
  GetCustomRoutesDataFirstPage = '[api] [customRoutes] [GetCustomRoutesDataFirstPage]',
  GetCustomRoutesDataNextPage = '[api] [customRoutes] [GetCustomRoutesDataNextPage]',
  GetCustomRoutesSuccess = '[api] [customRoutes] [GetCustomRoutesSuccess]',
  GetCustomRoutesFailure = '[api] [customRoutes] [GetCustomRoutesFailure]',

  SetCustomRoutesSort = '[api] [customRoutes] [SetCustomRoutesSort]',
  SetCustomRoutesFilters = '[api] [customRoutes] [SetCustomRoutesFilters]',
  ResetCustomRoutesFilters = '[api] [customRoutes] [ResetCustomRoutesFilters]',

  PostCustomRoute = '[api] [customRoutes] [PostCustomRoute]',
  PostCustomRouteSuccess = '[api] [customRoutes] [PostCustomRouteSuccess]',
  PostCustomRouteFailure = '[api] [customRoutes] [PostCustomRouteFailure]',
  CancelPostCustomRouteLoading = '[api] [customRoutes] [CancelPostCustomRouteLoading]',

  PatchCustomRoute = '[api] [customRoutes] [PatchCustomRoute]',
  PatchCustomRouteSuccess = '[api] [customRoutes] [PatchCustomRouteSuccess]',
  PatchCustomRouteFailure = '[api] [customRoutes] [PatchCustomRouteFailure]',

  DeleteCustomRoute = '[api] [customRoutes] [DeleteCustomRoute]',
  DeleteCustomRouteSuccess = '[api] [customRoutes] [DeleteCustomRouteSuccess]',
  DeleteCustomRouteFailure = '[api] [customRoutes] [DeleteCustomRouteFailure]',
  CancelDeleteCustomRouteLoading = '[api] [customRoutes] [CancelDeleteCustomRouteLoading]',

  ReloadCustomRoutes = '[api] [customRoutes] [ReloadCustomRoutes]',
  SetCustomRoutesData = '[api] [customRoutes] [SetCustomRoutesData]',
}
