import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import { ClientDetailActionTypes } from '@app/store/pages/clientDetail/clientDetail/clientDetail.constants'
import { BaseDataStore } from '@app/store/types'
import { ClientDetailDto } from '@shared/dto/clients.dto'

import * as clientDetailActions from '@app/store/pages/clientDetail/clientDetail/clientDetail.actions'

type ClientDetailAction = ActionType<typeof clientDetailActions>

export type ClientDetailStore = BaseDataStore<ClientDetailDto> & {
  isPatchClientLoading: boolean
  patchClientError: unknown
}

const initialClientDetailState: ClientDetailStore = {
  error: null,
  isLoading: false,
  data: null,

  isPatchClientLoading: false,
  patchClientError: null,
}

const clientDetailReducer = produce<ClientDetailStore, [ClientDetailAction]>(
  (state, action) => {
    switch (action.type) {
      case ClientDetailActionTypes.GetClientDetail:
        state.isLoading = true
        state.data = null
        state.error = null

        break

      case ClientDetailActionTypes.GetClientDetailSuccess:
        state.isLoading = false
        state.data = action.payload
        state.error = null

        break

      case ClientDetailActionTypes.GetClientDetailFailure:
        state.isLoading = false
        state.error = action.payload
        state.data = null

        break

      case ClientDetailActionTypes.PatchClient:
        state.isPatchClientLoading = true
        state.patchClientError = null

        break

      case ClientDetailActionTypes.PatchClientSuccess:
        state.isPatchClientLoading = false

        break

      case ClientDetailActionTypes.PatchClientFailure:
        state.isPatchClientLoading = false
        state.patchClientError = action.payload.error

        break

      default:
        return state
    }
  },
  initialClientDetailState,
)

export default clientDetailReducer
