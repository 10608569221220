import { action } from 'typesafe-actions'

import { AircraftDetailActionTypes } from '@app/store/pages/aircraftDetail/aircraftDetail/aircraftDetail.constants'
import { AircraftDetailDto, PartialAircraftDto } from '@shared/dto/aircraft.dto'

export const getAircraftDetailAction = (id: number) =>
  action(AircraftDetailActionTypes.GetAircraftDetail, id)

export const getAircraftDetailSuccessAction = (response: AircraftDetailDto) =>
  action(AircraftDetailActionTypes.GetAircraftDetailSuccess, response)

export const getAircraftDetailFailureAction = (error: unknown) =>
  action(AircraftDetailActionTypes.GetAircraftDetailFailure, error)

export const patchAircraftAction = (
  id: number,
  partialAircraft: PartialAircraftDto,
) =>
  action(AircraftDetailActionTypes.PatchAircraft, { id, data: partialAircraft })

export const patchAircraftSuccessAction = () =>
  action(AircraftDetailActionTypes.PatchAircraftSuccess)

export const patchAircraftFailureAction = (error: unknown) =>
  action(AircraftDetailActionTypes.PatchAircraftFailure, error)
