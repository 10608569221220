export enum ContactPersonsActionTypes {
  GetContactPersonsDataFirstPage = '[pages] [clientDetail] [contactPersons] [GetContactPersonsDataFirstPage]',
  GetContactPersonsDataNextPage = '[pages] [clientDetail] [contactPersons] [GetContactPersonsDataNextPage]',
  GetContactPersonsSuccess = '[pages] [clientDetail] [contactPersons] [GetContactPersonsSuccess]',
  GetContactPersonsFailure = '[pages] [clientDetail] [contactPersons] [GetContactPersonsFailure]',

  SetContactPersonsSort = '[pages] [clientDetail] [contactPersons] [SetContactPersonsSort]',
  SetContactPersonsFilters = '[pages] [clientDetail] [contactPersons] [SetContactPersonsFilters]',

  ResetContactPersonsFilters = '[pages] [clientDetail] [contactPersons] [ResetContactPersonsFilters]',
  ResetContactPersons = '[pages] [clientDetail] [contactPersons] [ResetContactPersons]',

  ReloadContactPersons = '[pages] [clientDetail] [contactPersons] [ReloadContactPersons]',
  ReloadContactPersonsSuccess = '[pages] [clientDetail] [contactPersons] [ReloadContactPersonsSuccess]',

  DeleteContactPerson = '[pages] [clientDetail] [clientDetail] [DeleteContactPerson]',
  DeleteContactPersonSuccess = '[pages] [clientDetail] [clientDetail] [DeleteContactPersonSuccess]',
  DeleteContactPersonFailure = '[pages] [clientDetail] [clientDetail] [DeleteContactPersonFailure]',
  CancelContactPersonDelete = '[pages] [clientDetail] [clientDetail] [CancelContactPersonDelete]',
}
