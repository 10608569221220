import React from 'react'
import { useTranslation } from 'react-i18next'

import Dialog, { DialogProps } from '@app/components/atoms/Dialog/Dialog'
import Button from '@app/components/atoms/Button/Button'

interface ConfirmationDialog extends DialogProps {
  onClose: () => void
  onCancel: () => void
  onConfirm: () => void
  confirmButtonText?: string
  cancelButtonText?: string
  isSubmitting?: boolean
}

const ConfirmationDialog = ({
  onCancel,
  onConfirm,
  confirmButtonText,
  cancelButtonText,
  isSubmitting,
  ...props
}: ConfirmationDialog): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog
      {...props}
      dialogActions={
        <>
          <Button
            disabled={isSubmitting}
            inverted
            onClick={onCancel}
            data-testid="ConfirmationDialog__cancel-button"
          >
            {cancelButtonText ?? t('molecules.ConfirmationDialog.cancel')}
          </Button>
          <Button
            loading={isSubmitting}
            autoFocus
            onClick={onConfirm}
            data-testid="ConfirmationDialog__submit-button"
          >
            {confirmButtonText ?? t('molecules.ConfirmationDialog.confirm')}
          </Button>
        </>
      }
    />
  )
}

export default ConfirmationDialog
