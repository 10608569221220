import React from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'

import LoadingSpinner from '@app/components/atoms/LoadingSpinner/LoadingSpinner'
import Logo from '@app/components/atoms/Logo/Logo'

// @todo How to strip unknown props from React Loadable ???
const LoadingContainer = (): JSX.Element => {
  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <LoadingSpinner loading size={200}>
        <StyledLogo />
      </LoadingSpinner>
    </Box>
  )
}

const StyledLogo = styled(Logo)`
  width: 5rem;
`

export default LoadingContainer
