import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import { RebookingActionTypes } from '@app/store/pages/requests/rebooking/rebooking.constants'
import * as RequestsRebookingActions from '@app/store/pages/requests/rebooking/rebooking.actions'

type RequestsListAction = ActionType<typeof RequestsRebookingActions>

export type RequestsListStore = {
  isRebookingDialogOpen: boolean
  isRebookingLoading: boolean
  rebookingError: unknown
}

const initialOfferDetailState: RequestsListStore = {
  isRebookingDialogOpen: false,
  isRebookingLoading: false,
  rebookingError: null,
}

const RequestsListReducer = produce<RequestsListStore, [RequestsListAction]>(
  (state, action) => {
    switch (action.type) {
      case RebookingActionTypes.SetIsRebookingDialogOpen:
        state.isRebookingDialogOpen = action.payload

        break

      case RebookingActionTypes.InitRebooking:
        state.isRebookingLoading = true

        break

      case RebookingActionTypes.OfferRebookingSuccess:
        state.isRebookingLoading = false

        break

      case RebookingActionTypes.OfferRebookingFailure:
        state.isRebookingLoading = false
        state.rebookingError = action.payload

        break

      case RebookingActionTypes.ResetRebookingState:
        return initialOfferDetailState

      default:
        return state
    }
  },
  initialOfferDetailState,
)

export default RequestsListReducer
