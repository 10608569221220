import React, { ReactNode } from 'react'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    background: ${({ theme }) => theme.palette.common.white};
  }

  // @todo Move directly to time picker component instead
  .flatpickr-calendar {
    max-width: 7.5rem;
  }
`

interface Props {
  children: ReactNode
}

const GlobalStyleProvider = ({ children }: Props): JSX.Element => {
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  )
}

export default GlobalStyleProvider
