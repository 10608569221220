export enum CreateRequestActionTypes {
  TriggerComputationPolling = '[pages] [requests] [createRequest] [TriggerComputationPolling]',
  StopComputationPolling = '[pages] [requests] [createRequest] [StopComputationPolling]',

  PostComputationFailure = '[pages] [requests] [createRequest] [PostComputationFailure]',

  WatchComputation = '[pages] [requests] [createRequest] [WatchComputation]',
  WatchComputationSuccess = '[pages] [requests] [createRequest] [WatchComputationSuccess]',
  WatchComputationFailure = '[pages] [requests] [createRequest] [WatchComputationFailure]',

  PostCreateOffersFromComputation = '[pages] [requests] [createRequest] [PostCreateOffersFromComputation]',
  PostCreateOffersFromComputationSuccess = '[pages] [requests] [createRequest] [PostCreateOffersFromComputationSuccess]',
  PostCreateOffersFromComputationFailure = '[pages] [requests] [createRequest] [PostCreateOffersFromComputationFailure]',

  ResetCreateRequestsState = '[pages] [requests] [createRequest] [ResetCreateRequestsState]',
}
