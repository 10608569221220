import { RootState } from '@app/store'

export const selectAirportListLimit = (store: RootState) =>
  store.pages.airports.airportList.limit

export const selectAirportListPage = (store: RootState) =>
  store.pages.airports.airportList.page

export const selectAirportListFilters = (store: RootState) =>
  store.pages.airports.airportList.filters

export const selectAirportListOrderBy = (store: RootState) =>
  store.pages.airports.airportList.orderBy

export const selectAirportListData = (store: RootState) =>
  store.pages.airports.airportList.data

export const selectAirportListTotal = (store: RootState) =>
  store.pages.airports.airportList.total

export const selectAirportListIsLoading = (store: RootState) =>
  store.pages.airports.airportList.isLoading

export const selectAirportListError = (store: RootState) =>
  store.pages.airports.airportList.error

export const selectAirportListOrderDirection = (store: RootState) =>
  store.pages.airports.airportList.orderDirection
