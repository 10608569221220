export enum Languages {
  En = 'en',
  Cs = 'cs',
}

export enum LocalStorageKeys {
  AuthToken = 'authToken',
}

// @todo Move to shared
//       (i18next scan build fails when shared is imported)
export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export const IS_BROWSER = typeof window !== 'undefined'

export const FALLBACK_LANGUAGE = Languages.En

export const DEFAULT_TRANSLATION_NAMESPACE = 'app'

export const DEFAULT_PAGE_LIMIT = 20

export const DEFAULT_SCHEDULE_PAGE_LIMIT = 80

export const DEFAULT_ORDER_DIRECTION = OrderDirection.Desc

export const DEFAULT_ORDER_BY = 'id'

export const DEFAULT_REQUEST_DEBOUNCE_IN_MILLISECONDS = 500

// @todo Refactor
export enum LegPriceBreakdownKeys {
  VariableHourlyCost = 'variable_hourly_cost',
  FlightDurationInMin = 'flight_duration_in_minutes',
  FlightTimeProfit = 'flight_time_profit',
  AirportFee = 'airport_fee',
  HandlingFee = 'handling_fee',
  CateringFee = 'catering_fee',
  DepartureFee = 'departure_fee',
  ArrivalFee = 'arrival_fee',
  OvernightFee = 'overnight_fee',
  OtherCost = 'other_cost',
}

export const DO_NOT_INVITE_STRING = 'do-not-invite'

export const USER_REGISTRATION_HASH_QUERY_NAME = 'hash'
