import { action } from 'typesafe-actions'

import { ConfirmationDialogProps } from '@app/store/ui/confirmationDialog/confirmationDialog.types'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'

export const openConfirmationDialogAction = (
  dialogProps: ConfirmationDialogProps,
) => action(ConfirmationDialogActionTypes.OpenConfirmationDialog, dialogProps)

export const submitConfirmationDialogAction = () =>
  action(ConfirmationDialogActionTypes.SubmitConfirmationDialog)

export const closeConfirmationDialogAction = () =>
  action(ConfirmationDialogActionTypes.CloseConfirmationDialog)
