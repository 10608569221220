import { RootState } from '@app/store'

export const selectUserInfo = (store: RootState) => store.core.userInfo.data

export const selectUserInfoIsLoading = (store: RootState) =>
  store.core.userInfo.isLoading

export const selectUserInfoError = (store: RootState) =>
  store.core.userInfo.error

export const selectIsPatchMyUserLoading = (store: RootState) =>
  store.core.userInfo.isPatchUserLoading

export const selectIsPutMyPasswordLoading = (store: RootState) =>
  store.core.userInfo.isPutPasswordLoading
