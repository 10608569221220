import { RootState } from '@app/store'

export const selectUserRegistrationData = (store: RootState) =>
  store.pages.userRegistration.userRegistration.userData

export const selectOperatorsRegistrationData = (store: RootState) =>
  store.pages.userRegistration.userRegistration.operatorsData

export const selectCompanyRegistrationData = (store: RootState) =>
  store.pages.userRegistration.userRegistration.companyData

export const selectUserRegistrationToken = (store: RootState) =>
  store.pages.userRegistration.userRegistration.userRegistrationToken

export const selectUserRegistrationActiveStep = (store: RootState) =>
  store.pages.userRegistration.userRegistration.activeStep

export const selectUserRegistrationType = (store: RootState) =>
  store.pages.userRegistration.userRegistration.userRegistrationType

export const selectUserRegistrationInvitationData = (store: RootState) =>
  store.pages.userRegistration.userRegistration.invitationData

export const selectIsSubmitRegistrationLoading = (store: RootState) =>
  store.pages.userRegistration.userRegistration.isSubmitRegistrationLoading
