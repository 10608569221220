export enum ScheduleActionTypes {
  GetScheduleDataFirstPage = '[pages] [schedule] [scheduleList] [GetScheduleDataFirstPage]',
  GetScheduleDataNextPage = '[pages] [schedule] [scheduleList] [GetScheduleDataNextPage]',
  GetScheduleSuccess = '[pages] [schedule] [scheduleList] [GetScheduleSuccess]',
  GetScheduleFailure = '[pages] [schedule] [scheduleList] [GetScheduleFailure]',

  StartSetScheduleSort = '[pages] [schedule] [scheduleList] [StartSetScheduleSort]',
  StartSetScheduleFilters = '[pages] [schedule] [scheduleList] [StartSetScheduleFilters]',
  SetScheduleParameters = '[pages] [schedule] [scheduleList] [SetScheduleParameters]',

  GetAllScheduleData = '[pages] [schedule] [scheduleList] [GetAllScheduleData]',
  GetAllScheduleDataSuccess = '[pages] [schedule] [scheduleList] [GetAllScheduleDataSuccess]',
  GetAllScheduleDataFailure = '[pages] [schedule] [scheduleList] [GetAllScheduleDataFailure]',

  ReloadScheduleList = '[pages] [schedule] [scheduleList] [ReloadScheduleList]',
  SetScheduleListData = '[pages] [schedule] [scheduleList] [SetScheduleListData]',
}
