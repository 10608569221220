// withTranslation

import { call, put, takeLatest } from 'redux-saga/effects'

import { api } from '@app/utils/api/api'
import { getErrorMessage } from '@app/utils/errorHandling'
import { UserOperatorsActionTypes } from '@app/store/core/userOperators/userOperators.constants'
import { addNotificationAction } from '@app/store/ui/notifications/notifications.actions'

import * as actions from '@app/store/core/userOperators/userOperators.actions'

function* getUserOperatorsSaga(
  action: ReturnType<typeof actions.getUserOperatorsAction>,
) {
  try {
    const { data } = yield call(api.getMyOperators)
    yield put(actions.getUserOperatorsSuccessAction(data, action.payload))
  } catch (error) {
    const errorMessageKey = getErrorMessage(error, {
      // t('errors.general.unauthorized')
      401: 'errors.general.unauthorized',
      // t('errors.getUserOperators.notFound')
      404: 'errors.getUserOperators.notFound',
      // t('errors.getUserOperators.default')
      default: 'errors.getUserOperators.default',
    })

    if (errorMessageKey) {
      yield put(
        addNotificationAction({
          i18nextKey: errorMessageKey,
          type: 'error',
        }),
      )
    }

    yield put(actions.getUserOperatorsFailureAction(error))
  }
}

export default function* watchUserOperatorsSaga(): Generator {
  yield takeLatest(
    UserOperatorsActionTypes.GetUserOperators,
    getUserOperatorsSaga,
  )
}
