import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import { AirportDetailActionTypes } from '@app/store/pages/airportDetail/airportDetail/airportDetail.constants'
import { BaseDataStore } from '@app/store/types'
import * as airportDetailActions from '@app/store/pages/airportDetail/airportDetail/airportDetail.actions'
import { AirportDetailDto } from '@shared/dto/airports.dto'

type AirportDetailAction = ActionType<typeof airportDetailActions>

export type AirportDetailStore = BaseDataStore<AirportDetailDto>

const initialAirportDetailState: AirportDetailStore = {
  error: null,
  isLoading: false,
  data: null,
}

const airportDetailReducer = produce<AirportDetailStore, [AirportDetailAction]>(
  (state, action) => {
    switch (action.type) {
      case AirportDetailActionTypes.GetAirportDetail:
        state.isLoading = true
        state.data = null
        state.error = null

        break

      case AirportDetailActionTypes.GetAirportDetailSuccess:
        state.isLoading = false
        state.data = action.payload
        state.error = null

        break

      case AirportDetailActionTypes.GetAirportDetailFailure:
        state.isLoading = false
        state.error = action.payload
        state.data = null

        break

      default:
        return state
    }
  },
  initialAirportDetailState,
)

export default airportDetailReducer
