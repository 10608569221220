import React, { ReactNode, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { removeNotificationAction } from '@app/store/ui/notifications/notifications.actions'
import { selectNotifications } from '@app/store/ui/notifications/notifications.selectors'

interface NotificationsProviderProps {
  children: ReactNode
}

const NotificationsProvider = ({
  children,
}: NotificationsProviderProps): JSX.Element => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const notifications = useSelector(selectNotifications)

  useEffect(() => {
    notifications.forEach((notification) => {
      enqueueSnackbar(t(notification.i18nextKey), {
        variant: notification.type,
      })

      dispatch(removeNotificationAction(notification.id))
    })
  }, [notifications])

  return <>{children}</>
}

export default NotificationsProvider
