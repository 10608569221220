export enum RequestDetailActionTypes {
  CheckOpenOfferDetailState = '[pages] [requests] [requestDetail] [CheckOpenOfferDetailState]',
  UpdateOfferStatus = '[pages] [requests] [requestDetail] [UpdateOfferStatus]',

  GetOfferDocumentIds = '[pages] [requests] [requestDetail] [offerDocumentIds]',
  AddOfferDocumentId = '[pages] [requests] [requestDetail] [addOfferDocumentId]',
  RemoveOfferDocumentId = '[pages] [requests] [requestDetail] [removeDocumentId]',

  GetRequestDetail = '[pages] [requests] [requestDetail] [GetRequestDetail]',
  GetRequestDetailSuccess = '[pages] [requests] [requestDetail] [GetRequestDetailSuccess]',
  GetRequestDetailFailure = '[pages] [requests] [requestDetail] [GetRequestDetailFailure]',

  PatchOffer = '[pages] [requests] [requestDetail] [PatchOffer]',
  PatchOfferSuccess = '[pages] [requests] [requestDetail] [PatchOfferSuccess]',
  PatchOfferFailure = '[pages] [requests] [requestDetail] [PatchOfferFailure]',

  DeleteOffer = '[pages] [requests] [requestDetail] [DeleteOffer]',
  DeleteOfferSuccess = '[pages] [requests] [requestDetail] [DeleteOfferSuccess]',
  DeleteOfferFailure = '[pages] [requests] [requestDetail] [DeleteOfferFailure]',

  RecalculateOffer = '[pages] [requests] [requestDetail] [RecalculateOffer]',
  RecalculateOfferSuccess = '[pages] [requests] [requestDetail] [RecalculateOfferSuccess]',
  RecalculateOfferFailure = '[pages] [requests] [requestDetail] [RecalculateOfferFailure]',

  PatchRequest = '[pages] [requests] [requestDetail] [PatchRequest]',
  PatchRequestSuccess = '[pages] [requests] [requestDetail] [PatchRequestSuccess]',
  PatchRequestFailure = '[pages] [requests] [requestDetail] [PatchRequestFailure]',

  GetOfferSchedule = '[pages] [requests] [requestDetail] [GetOfferSchedule]',
  GetOfferScheduleSuccess = '[pages] [requests] [requestDetail] [GetOfferScheduleSuccess]',
  GetOfferScheduleFailure = '[pages] [requests] [requestDetail] [GetOfferScheduleFailure]',

  GetLegEditorSchedule = '[pages] [requests] [requestDetail] [GetLegEditorSchedule]',
  GetLegEditorScheduleSuccess = '[pages] [requests] [requestDetail] [GetLegEditorScheduleSuccess]',
  GetLegEditorScheduleFailure = '[pages] [requests] [requestDetail] [GetLegEditorScheduleFailure]',

  GetChat = '[pages] [requests] [requestDetail] [GetChat]',
  GetChatSuccess = '[pages] [requests] [requestDetail] [GetChatSuccess]',
  GetChatFailure = '[pages] [requests] [requestDetail] [GetChatFailure]',

  PostChatMessage = '[pages] [requests] [requestDetail] [PostChatMessage]',
  PostChatMessageSuccess = '[pages] [requests] [requestDetail] [PostChatMessageSuccess]',
  PostChatMessageFailure = '[pages] [requests] [requestDetail] [PostChatMessageFailure]',

  MarkChatMessagesAsRead = '[pages] [requests] [requestDetail] [MarkChatMessagesAsRead]',

  CancelOffer = '[pages] [requests] [requestDetail] [CancelOffer]',
  CancelOfferSuccess = '[pages] [requests] [requestDetail] [CancelOfferSuccess]',
  CancelOfferFailure = '[pages] [requests] [requestDetail] [CancelOfferFailure]',

  GetOfferRelatedCustomRoutes = '[pages] [requests] [requestDetail] [GetOfferRelatedCustomRoutes]',
  GetOfferRelatedCustomRoutesSuccess = '[pages] [requests] [requestDetail] [GetOfferRelatedCustomRoutesSuccess]',
  GetOfferRelatedCustomRoutesFailure = '[pages] [requests] [requestDetail] [GetOfferRelatedCustomRoutesFailure]',

  GetOfferRelatedAirportFees = '[pages] [requests] [requestDetail] [GetOfferRelatedAirportFees]',
  GetOfferRelatedAirportFeesSuccess = '[pages] [requests] [requestDetail] [GetOfferRelatedAirportFeesSuccess]',
  GetOfferRelatedAirportFeesFailure = '[pages] [requests] [requestDetail] [GetOfferRelatedAirportFeesFailure]',

  SetOpenOfferId = '[pages] [requests] [requestDetail] [SetOpenOfferId]',

  ReloadRequestDetail = '[pages] [requests] [requestDetail] [ReloadRequestDetail]',

  ResetRecalculationState = '[pages] [requests] [requestDetail] [ResetRecalculationState]',

  ResetRequestDetailState = '[pages] [requests] [requestDetail] [ResetRequestDetailState]',

  IgnoreLegsFromOptimization = '[pages] [requests] [requestDetail] [IgnoreLegsFromOptimization]',
  IgnoreLegsFromOptimizationSuccess = '[pages] [requests] [requestDetail] [IgnoreLegsFromOptimizationSuccess]',
  IgnoreLegsFromOptimizationFailure = '[pages] [requests] [requestDetail] [IgnoreLegsFromOptimizationFailure]',
  ResetIgnoreLegsFromOptimizationState = '[pages] [requests] [requestDetail] [ResetIgnoreLegsFromOptimizationState]',
}
