import React from 'react'
import styled from 'styled-components'

import BaseTypography, {
  TypographyProps as BaseTypographyProps,
} from '@material-ui/core/Typography'

type Variants =
  | 'default'
  | 'secondary'
  | 'title'
  | 'heading'
  | 'subtitle'
  | 'content'
  | 'mainHeading'
  | 'formTitle'
  | 'formSectionTitle'
  | 'userTitle'
  | 'boldText'
  | 'sectionTitle'
  | 'label'
  | 'dialogHeading'
  | 'scheduleLabel'
  | 'smallDarkText'
  | 'reportsItem'

export interface TypographyProps extends Omit<BaseTypographyProps, 'variant'> {
  variant?: Variants
  customColor?: string
}

interface StyledTypographyProps extends Omit<BaseTypographyProps, 'variant'> {
  $variant: TypographyProps['variant']
}

const Typography = ({
  variant = 'default',
  ...props
}: TypographyProps): JSX.Element => (
  <StyledTypography $variant={variant} {...props} />
)

const StyledTypography = styled(BaseTypography)<StyledTypographyProps>`
  ${({ $variant, theme }) => {
    switch ($variant) {
      case 'secondary':
        return `
          color: ${theme.palette.grey[700]};
          font-family: ${theme.font.secondary};
          font-size: 1rem;
          font-weight: 400;`

      case 'content':
        return `
          color: ${theme.palette.grey[800]};
          font-family: ${theme.font.secondary};
          font-size: 0.875rem;
          font-weight: 400;`

      case 'mainHeading':
        return `
          color: ${theme.text.heading.regular};
          font-family: ${theme.font.primary};
          font-size: 1.75rem;
          font-weight: 500;`

      case 'title':
        return `
          color: ${theme.palette.grey[600]};
          font-family: ${theme.font.secondary};
          font-size: 0.625rem;
          font-weight: 400;`

      case 'subtitle':
        return `
          color: ${theme.palette.grey[500]};
          font-family: ${theme.font.secondary};
          font-size: 0.75rem;
          font-weight: 400`

      case 'heading':
        return `
          color: ${theme.text.heading.regular};
          font-family: ${theme.font.primary};
          font-size: 2.25rem;
          font-weight: 500;`

      case 'formTitle':
        return `
          color: ${theme.text.heading.regular};
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 1rem;
          `

      case 'formSectionTitle':
        return `
          color: ${theme.text.heading.regular};
          font-size: 1.375rem;
          font-weight: 600;
          margin-bottom: 1rem;
          `

      case 'userTitle':
        return `
          color: ${theme.text.heading.regular};
          font-family: ${theme.font.primary};
          font-size: 1.375rem;
          font-weight: 500;
          `

      case 'boldText':
        return `font-weight: 800;`

      case 'sectionTitle':
        return `
          font-weight: 600;
          color: ${theme.palette.grey[700]};
          font-size: 1rem;
          margin-bottom: 1rem;
        `

      case 'label':
        return `
          font-size: 0.75rem;
          color: ${theme.text.heading.dark};
        `

      case 'dialogHeading':
        return `
          font-size: 1.6rem;
          margin: 0.8rem 0;
          color: ${theme.text.heading.regular};
          font-weight: bold;
        `

      case 'scheduleLabel':
        return `
          color: ${theme.palette.grey[800]};
          font-family: ${theme.font.secondary};
          font-size: 0.875rem;
          font-weight: bold;`

      case 'smallDarkText':
        return `
          color: ${theme.palette.grey[800]};
          font-size: 0.75rem;
        `

      case 'reportsItem':
        return `
          font-family: ${theme.font.primary};
          font-size: 1.5rem;
        `

      default:
        return `
          color: ${theme.palette.grey[800]};
          font-family: ${theme.font.secondary};
          font-size: 1rem;
          font-weight: 400;`
    }
  }}
`

export default Typography
