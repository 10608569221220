import { RootState } from '@app/store'

export const selectCreateRequestIsLoading = (store: RootState) =>
  store.pages.requests.createRequest.isLoading

export const selectCreateRequestIsPolling = (store: RootState) =>
  store.pages.requests.createRequest.isPolling

export const selectCreateRequestError = (store: RootState) =>
  store.pages.requests.createRequest.error

export const selectCreateRequestData = (store: RootState) =>
  store.pages.requests.createRequest.data

export const selectCreateRequestComputationId = (store: RootState) =>
  store.pages.requests.createRequest.computationId
