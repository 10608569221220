import { RootState } from '@app/store'

export const selectClientsLimit = (store: RootState) =>
  store.pages.clients.clientsList.limit

export const selectClientsPage = (store: RootState) =>
  store.pages.clients.clientsList.page

export const selectClientsFilters = (store: RootState) =>
  store.pages.clients.clientsList.filters

export const selectClientsOrderBy = (store: RootState) =>
  store.pages.clients.clientsList.orderBy

export const selectClientsData = (store: RootState) =>
  store.pages.clients.clientsList.data

export const selectClientsTotal = (store: RootState) =>
  store.pages.clients.clientsList.total

export const selectClientsIsLoading = (store: RootState) =>
  store.pages.clients.clientsList.isLoading

export const selectClientsIsReloading = (store: RootState) =>
  store.pages.clients.clientsList.isReloading

export const selectClientsError = (store: RootState) =>
  store.pages.clients.clientsList.error

export const selectClientsOrderDirection = (store: RootState) =>
  store.pages.clients.clientsList.orderDirection

export const selectIsDeleteClientLoading = (store: RootState) =>
  store.pages.clients.clientsList.isDeleteClientLoading

export const selectDeleteClientError = (store: RootState) =>
  store.pages.clients.clientsList.deleteClientError
