export enum UserInfoActionTypes {
  GetMyUserInfo = '[core] [userInfo] [GetMyUserInfo]',
  GetMyUserInfoSuccess = '[core] [userInfo] [GetMyUserInfoSuccess]',
  GetMyUserInfoFailure = '[core] [userInfo] [GetMyUserInfoFailure]',

  GetUserInfo = '[core] [userInfo] [GetUserInfo]',
  GetUserInfoSuccess = '[core] [userInfo] [GetUserInfoSuccess]',
  GetUserInfoFailure = '[core] [userInfo] [GetUserInfoFailure]',

  PatchMyUser = '[core] [userInfo] [PatchMyUser]',
  PatchMyUserSuccess = '[core] [userInfo] [PatchMyUserSuccess]',
  PatchMyUserFailure = '[core] [userInfo] [PatchMyUserFailure]',

  PatchUser = '[core] [userInfo] [PatchUser]',
  PatchUserSuccess = '[core] [userInfo] [PatchUserSuccess]',
  PatchUserFailure = '[core] [userInfo] [PatchUserFailure]',

  PutMyPassword = '[core] [userInfo] [PutMyPassword]',
  PutMyPasswordSuccess = '[core] [userInfo] [PutMyPasswordSuccess]',
  PutMyPasswordFailure = '[core] [userInfo] [PutMyPasswordFailure]',

  ResetUserInfoState = '[core] [userInfo] [ResetUserInfoState]',
}
