import { RootState } from '@app/store'

export const selectRequestsReports = (store: RootState) =>
  store.pages.reports.requestsReports.data

export const selectRequestsReportsIsLoading = (store: RootState) =>
  store.pages.reports.requestsReports.isLoading

export const selectRequestsReportsError = (store: RootState) =>
  store.pages.reports.requestsReports.error

export const selectRequestsReportsFilters = (store: RootState) =>
  store.pages.reports.requestsReports.filters
