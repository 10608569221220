import { RootState } from '@app/store'

export const selectUserOperators = (store: RootState) =>
  store.core.userOperators.data

export const selectUserOperatorsIsLoading = (store: RootState) =>
  store.core.userOperators.isLoading

export const selectUserOperatorsError = (store: RootState) =>
  store.core.userOperators.error

export const selectSelectedOperator = (store: RootState) =>
  store.core.userOperators.selectedOperator
