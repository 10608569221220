import React, { useRef, useEffect, useState, ReactNode } from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'

import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress'

import { lighten } from '@material-ui/core/styles'

interface LoadingSpinnerProps extends Omit<CircularProgressProps, 'color'> {
  children?: ReactNode
  loading?: boolean
  spacing?: number
  color?: string
}

interface StyledCircularProgressProps extends CircularProgressProps {
  $color?: string
}

const LoadingSpinner = ({
  children,
  loading,
  size,
  color,
  className,
  thickness = 2,
  spacing = 10,
  ...props
}: LoadingSpinnerProps): JSX.Element => {
  const [spinnerSize, setSpinnerSize] = useState<number | undefined>(undefined)

  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!contentRef.current) {
      return
    }

    const nextSpinnerSize = Math.sqrt(
      contentRef.current.offsetWidth ** 2 +
        contentRef.current.offsetHeight ** 2,
    )

    setSpinnerSize(nextSpinnerSize + spacing)
  }, [spacing])

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      className={className}
    >
      <ContentContainer ref={contentRef}>{children}</ContentContainer>
      <SpinnerContainer $loading={loading}>
        <PrimaryCircularProgress
          $color={color}
          thickness={thickness}
          value={25}
          size={size ?? spinnerSize}
          {...props}
        />
        <SecondaryCircularProgress
          $color={color}
          thickness={thickness}
          variant="determinate"
          value={100}
          size={size ?? spinnerSize}
        />
      </SpinnerContainer>
    </Box>
  )
}

const ContentContainer = styled.div`
  position: absolute;
`

const SpinnerContainer = styled.div<{
  $loading: LoadingSpinnerProps['loading']
}>`
  opacity: ${({ $loading }) => !$loading && 0};
`

const PrimaryCircularProgress = styled(
  CircularProgress,
)<StyledCircularProgressProps>`
  color: ${({ $color, theme }) => $color ?? theme.palette.primary.main};
`

const SecondaryCircularProgress = styled(
  CircularProgress,
)<StyledCircularProgressProps>`
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  z-index: -1;

  color: ${({ $color, theme }) =>
    lighten($color ?? theme.palette.primary.main, 0.5)};
`

export default LoadingSpinner
