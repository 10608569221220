export enum ContactPersonsListActionTypes {
  GetContactPersonsListDataFirstPage = '$type [GetContactPersonsListDataFirstPage]',
  GetContactPersonsListDataNextPage = '$type [GetContactPersonsListDataNextPage]',
  GetContactPersonsListSuccess = '$type [GetContactPersonsListSuccess]',
  GetContactPersonsListFailure = '$type [GetContactPersonsListFailure]',

  SetContactPersonsListSort = '$type [SetContactPersonsListSort]',
  SetContactPersonsListFilters = '$type [SetContactPersonsListFilters]',

  ResetContactPersonsListFilters = '$type [ResetContactPersonsListFilters]',
  ResetContactPersonsList = '$type [ResetContactPersonsList]',

  ReloadContactPersonsList = '$type [ReloadContactPersonsList]',
  ReloadContactPersonsListSuccess = '$type [ReloadContactPersonsListSuccess]',
}
