import React from 'react'
import styled from 'styled-components'

import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'

import BaseDialog, {
  DialogProps as BaseDialogProps,
} from '@material-ui/core/Dialog'

import Typography from '@app/components/atoms/Typography/Typography'

export interface DialogProps extends BaseDialogProps {
  withCloseIcon?: boolean
  dialogTitle?: JSX.Element | string
  dialogContent?: JSX.Element
  dialogActions?: JSX.Element
}

interface StyledDialogProps
  extends Omit<DialogProps, 'dialogTitle' | 'dialogContent' | 'dialogActions'> {
  $withPadding: boolean
}

const Dialog = ({
  children,
  onClose,
  dialogTitle,
  dialogActions,
  dialogContent,
  withCloseIcon = true,
  ...props
}: DialogProps): JSX.Element => {
  return (
    <StyledDialog
      $withPadding={!children}
      onClose={onClose}
      classes={{
        paper: 'Dialog__paper',
      }}
      {...props}
    >
      {withCloseIcon && (
        <IconContainer data-testid="Dialog__close-icon">
          <IconButton onClick={(event) => onClose?.(event, 'escapeKeyDown')}>
            <CloseIcon />
          </IconButton>
        </IconContainer>
      )}

      {children}

      {dialogTitle && (
        <StyledDialogTitle disableTypography>
          {typeof dialogTitle === 'string' ? (
            <Typography variant="heading">{dialogTitle}</Typography>
          ) : (
            dialogTitle
          )}
        </StyledDialogTitle>
      )}

      {dialogContent && <DialogContent>{dialogContent}</DialogContent>}

      {dialogActions && (
        <StyledDialogActions>{dialogActions}</StyledDialogActions>
      )}
    </StyledDialog>
  )
}

const StyledDialog = styled(BaseDialog)<StyledDialogProps>`
  backdrop-filter: blur(2px);

  & .Dialog__paper {
    ${({ $withPadding }) => $withPadding && 'padding: 1rem 2rem 2rem;'}
  }
`

const StyledDialogTitle = styled(DialogTitle)`
  padding-bottom: 2rem;
`

const StyledDialogActions = styled(DialogActions)`
  padding-top: 2rem;
`

const IconContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

export default Dialog
