import * as yup from 'yup'

export const generalSpecificationDataSchema = yup.object().shape({
  is_connected_to_avinode: yup.boolean().required(),
  manufacturer: yup.string().optional(),
  type: yup.string().optional(),
  icao_code: yup.string().optional(),
  registration_code: yup.string().required(),
  serial_code: yup.string().optional(),
  manufacturing_year: yup.number(),
  interior_refurbish_year: yup.number(),
  exterior_refurbish_year: yup.number(),
  base_airport_id: yup.number().required(),
  turnaround_before_empty_leg_in_minutes: yup.number().required(),
  turnaround_before_occupied_leg_in_minutes: yup.number().required(),
  time_in_destination_in_minutes: yup.number().required(),
})

export const performanceDataSchema = yup.object().shape({
  maximum_takeoff_weight_in_kilograms: yup.number().optional(),
  minimum_runway_length_in_feet: yup.number().optional(),
  takeoff_speed_in_knots: yup.number().required(),
  rate_of_climb_in_feet_per_minute: yup.number().required(),
  cruise_speed_in_knots: yup.number().required(),
  cruise_altitude_in_feet: yup.number().required(),
  landing_speed_in_knots: yup.number().required(),
  rate_of_descent_in_feet_per_minute: yup.number().required(),
  flight_range: yup
    .array()
    .of(
      yup.object().shape({
        passenger_count: yup.number().required(),
        flight_range_in_nautical_miles: yup.number().required(),
      }),
    )
    .min(1),
})

export const pricingDataSchema = yup.object().shape({
  average_fuel_price_per_gallon: yup.number().required(),
  average_fuel_consumption_in_gallons_per_hour: yup.number().required(),
  airframe_maintenance_budget_per_hour: yup.number().required(),
  engine_maintenance_budget_per_hour: yup.number().required(),
  avionics_maintenance_budget_per_hour: yup.number().required(),
  crew_budget_per_hour: yup.number().required(),
  other_costs_per_hour: yup.number().required(),
  occupied_leg_profit_per_hour: yup.number().required(),
  occupied_leg_minimal_profit: yup.number().required(),
  occupied_leg_fixed_fee: yup.number().required(),
  empty_leg_profit_per_hour: yup.number().required(),
  empty_leg_minimal_profit: yup.number().required(),
  empty_leg_fixed_fee: yup.number().required(),
  default_airport_fee: yup.number().required(),
  default_handling_fee: yup.number().required(),
  short_catering_fee_duration_in_minutes: yup.number().required(),
  short_catering_fee_per_pax: yup.number().required(),
  medium_catering_fee_per_pax: yup.number().required(),
  long_catering_fee_duration_in_minutes: yup.number().required(),
  long_catering_fee_per_pax: yup.number().required(),
})
