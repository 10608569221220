export enum AirportFeesActionTypes {
  GetAirportFeesDataFirstPage = '[api] [airportFees] [GetAirportFeesDataFirstPage]',
  GetAirportFeesDataNextPage = '[api] [airportFees] [GetAirportFeesDataNextPage]',
  GetAirportFeesSuccess = '[api] [airportFees] [GetAirportFeesSuccess]',
  GetAirportFeesFailure = '[api] [airportFees] [GetAirportFeesFailure]',

  SetAirportFeesSort = '[api] [airportFees] [SetAirportFeesSort]',
  SetAirportFeesFilters = '[api] [airportFees] [SetAirportFeesFilters]',
  ResetAirportFeesFilters = '[api] [airportFees] [ResetAirportFeesFilters]',

  PostAirportFee = '[api] [airportFees] [PostAirportFee]',
  PostAirportFeeSuccess = '[api] [airportFees] [PostAirportFeeSuccess]',
  PostAirportFeeFailure = '[api] [airportFees] [PostAirportFeeFailure]',
  CancelPostAirportFeeLoading = '[api] [airportFees] [CancelPostAirportFeeLoading]',

  PatchAirportFee = '[api] [airportFees] [PatchAirportFee]',
  PatchAirportFeeSuccess = '[api] [airportFees] [PatchAirportFeeSuccess]',
  PatchAirportFeeFailure = '[api] [airportFees] [PatchAirportFeeFailure]',

  DeleteAirportFee = '[api] [airportFees] [DeleteAirportFee]',
  DeleteAirportFeeSuccess = '[api] [airportFees] [DeleteAirportFeeSuccess]',
  DeleteAirportFeeFailure = '[api] [airportFees] [DeleteAirportFeeFailure]',
  CancelDeleteAirportFeeLoading = '[api] [airportFees] [CancelDeleteAirportFeeLoading]',

  ReloadAirportFees = '[api] [airportFees] [ReloadAirportFees]',
  SetAirportFeesData = '[api] [airportFees] [SetAirportFeesData]',
}
