import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import { ClientsActionTypes } from '@app/store/api/clients/clients.constants'

import * as clientsActions from '@app/store/api/clients/clients.actions'

type ClientsAction = ActionType<typeof clientsActions>

export type ClientsState = {
  isPostClientLoading: boolean
  postClientError: unknown
  createdClientId: number | null
}

const initialClientsState: ClientsState = {
  isPostClientLoading: false,
  postClientError: null,
  createdClientId: null,
}

const clientsReducer = produce<ClientsState, [ClientsAction]>(
  (state, action) => {
    switch (action.type) {
      case ClientsActionTypes.PostClient:
        state.isPostClientLoading = true
        state.postClientError = null
        state.createdClientId = null

        break

      case ClientsActionTypes.PostClientSuccess:
        state.isPostClientLoading = false
        state.createdClientId = action.payload.response.id

        break

      case ClientsActionTypes.PostClientFailure:
        state.isPostClientLoading = false
        state.postClientError = action.payload.error

        break

      default:
        return state
    }
  },
  initialClientsState,
)

export default clientsReducer
