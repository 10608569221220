import { RootState } from '@app/store'

export const selectCreateAircraftPartialData = (store: RootState) =>
  store.pages.createAircraft.createAircraft.data

export const selectCreateAircraftIsLoading = (store: RootState) =>
  store.pages.createAircraft.createAircraft.isLoading

export const selectCreateAircraftError = (store: RootState) =>
  store.pages.createAircraft.createAircraft.error
