import { ActionType } from 'typesafe-actions'
import produce, { castDraft } from 'immer'

import { ContactPersonsActionTypes } from '@app/store/pages/clientDetail/contactPersons/contactPersons.constants'
import { ListStore } from '@app/store/types'
import { GetContactPersonsFilters } from '@app/utils/api/types'
import { ContactPersonDetailDto } from '@shared/dto/contactPerson.dto'
import { ContactPersonsOrderBy } from '@shared/enums'

import * as ContactPersonsActions from '@app/store/pages/clientDetail/contactPersons/contactPersons.actions'

import { DEFAULT_ORDER_DIRECTION, DEFAULT_PAGE_LIMIT } from '@app/constants'

type ContactPersonsAction = ActionType<typeof ContactPersonsActions>

export type ContactPersonsStore = Omit<
  ListStore<ContactPersonDetailDto, GetContactPersonsFilters>,
  'orderBy'
> & {
  isReloading: boolean
  orderBy: ContactPersonsOrderBy

  isDeleteContactPersonLoading: boolean
  deleteContactPersonError: unknown
}

const initialContactPersonsState: ContactPersonsStore = {
  error: null,
  isLoading: false,
  isReloading: false,
  data: null,
  total: null,
  page: 1,
  limit: DEFAULT_PAGE_LIMIT,
  orderBy: ContactPersonsOrderBy.CreatedAt,
  orderDirection: DEFAULT_ORDER_DIRECTION,
  filters: {},

  isDeleteContactPersonLoading: false,
  deleteContactPersonError: null,
}

const ContactPersonsReducer = produce<
  ContactPersonsStore,
  [ContactPersonsAction]
>((state, action) => {
  switch (action.type) {
    case ContactPersonsActionTypes.GetContactPersonsDataFirstPage:
      state.isLoading = true
      state.data = null
      state.error = null
      state.total = 0
      state.limit = DEFAULT_PAGE_LIMIT
      state.page = 1

      break

    case ContactPersonsActionTypes.GetContactPersonsSuccess:
      // @see https://immerjs.github.io/immer/typescript/#cast-utilities
      state.data ??= []
      state.data.push(...castDraft(action.payload.data))

      state.isLoading = false
      state.error = null
      state.total = action.payload.total
      state.page = action.payload.page

      break

    case ContactPersonsActionTypes.GetContactPersonsFailure:
      state.isLoading = false
      state.error = action.payload

      break

    case ContactPersonsActionTypes.GetContactPersonsDataNextPage:
      state.isLoading = true

      break

    case ContactPersonsActionTypes.ResetContactPersonsFilters:
      state.filters = {}

      break

    case ContactPersonsActionTypes.SetContactPersonsSort:
      if (action.payload.orderBy) {
        state.orderBy = action.payload.orderBy
      }

      if (action.payload.orderDirection) {
        state.orderDirection = action.payload.orderDirection
      }

      break

    case ContactPersonsActionTypes.SetContactPersonsFilters:
      if (action.payload.filters) {
        state.filters = { ...state.filters, ...action.payload.filters }
      }

      break

    case ContactPersonsActionTypes.ReloadContactPersons:
      state.isReloading = true

      break

    case ContactPersonsActionTypes.ReloadContactPersonsSuccess:
      state.isReloading = false
      state.error = null
      state.data = action.payload.data
      state.total = action.payload.total

      break

    case ContactPersonsActionTypes.ResetContactPersons:
      state.error = initialContactPersonsState.error
      state.isLoading = initialContactPersonsState.isLoading
      state.data = initialContactPersonsState.data
      state.total = initialContactPersonsState.total
      state.page = initialContactPersonsState.page
      state.limit = initialContactPersonsState.limit
      state.orderBy = initialContactPersonsState.orderBy
      state.orderDirection = initialContactPersonsState.orderDirection
      state.filters = initialContactPersonsState.filters

      break

    case ContactPersonsActionTypes.DeleteContactPerson:
      state.isDeleteContactPersonLoading = true
      state.deleteContactPersonError = null

      break

    case ContactPersonsActionTypes.DeleteContactPersonSuccess:
      state.isDeleteContactPersonLoading = false

      break

    case ContactPersonsActionTypes.DeleteContactPersonFailure:
      state.isDeleteContactPersonLoading = false
      state.deleteContactPersonError = action.payload.error

      break

    case ContactPersonsActionTypes.CancelContactPersonDelete:
      state.isDeleteContactPersonLoading = false

      break

    default:
      return state
  }
}, initialContactPersonsState)

export default ContactPersonsReducer
