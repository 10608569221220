export enum AircraftListActionTypes {
  GetAircraftListDataFirstPage = '[pages] [fleet] [aircraftList] [GetAircraftListDataFirstPage]',
  GetAircraftListDataNextPage = '[pages] [fleet] [aircraftList] [GetAircraftListDataNextPage]',
  GetAircraftListSuccess = '[pages] [fleet] [aircraftList] [GetAircraftListSuccess]',
  GetAircraftListFailure = '[pages] [fleet] [aircraftList] [GetAircraftListFailure]',

  StartSetAircraftListSort = '[pages] [fleet] [aircraftList] [StartSetAircraftListSort]',
  StartSetAircraftListFilters = '[pages] [fleet] [aircraftList] [StartSetAircraftListFilters]',
  SetAircraftListParameters = '[pages] [fleet] [aircraftList] [SetAircraftListParameters]',

  DeleteAircraft = '[pages] [fleet] [aircraftList] [DeleteAircraft]',
  DeleteAircraftSuccess = '[pages] [fleet] [aircraftList] [DeleteAircraftSuccess]',
}
