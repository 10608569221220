import produce from 'immer'
import { ActionType } from 'typesafe-actions'
import { nanoid } from 'nanoid'

import { NotificationActionTypes } from '@app/store/ui/notifications/notifications.constants'
import { Notification } from '@app/store/ui/notifications/notifications.types'
import * as notificationActions from '@app/store/ui/notifications/notifications.actions'

type NotificationAction = ActionType<typeof notificationActions>

type NotificationStore = {
  messages: Required<Notification>[]
}

const initialNotificationsState: NotificationStore = {
  messages: [],
}

const reducerNotifications = produce<NotificationStore, [NotificationAction]>(
  (state, action) => {
    switch (action.type) {
      case NotificationActionTypes.AddNotification:
        state.messages.push({
          id: nanoid(),
          ...action.payload,
        })

        break

      case NotificationActionTypes.RemoveNotification:
        state.messages = state.messages.filter(
          (notification) => notification.id !== action.payload,
        )

        break

      default:
        return state
    }
  },
  initialNotificationsState,
)

export default reducerNotifications
