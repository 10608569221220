import { RootState } from '@app/store'

export const selectAirportFeesLimit = (store: RootState) =>
  store.api.airportFees.limit

export const selectAirportFeesPage = (store: RootState) =>
  store.api.airportFees.page

export const selectAirportFeesFilters = (store: RootState) =>
  store.api.airportFees.filters

export const selectAirportFeesOrderBy = (store: RootState) =>
  store.api.airportFees.orderBy

export const selectAirportFeesData = (store: RootState) =>
  store.api.airportFees.data

export const selectAirportFeesTotal = (store: RootState) =>
  store.api.airportFees.total

export const selectAirportFeesIsLoading = (store: RootState) =>
  store.api.airportFees.isLoading

export const selectAirportFeesError = (store: RootState) =>
  store.api.airportFees.error

export const selectAirportFeesOrderDirection = (store: RootState) =>
  store.api.airportFees.orderDirection

export const selectIsPostAirportFeeLoading = (store: RootState) =>
  store.api.airportFees.isPostLoading

export const selectIsPatchAirportFeeLoading = (store: RootState) =>
  store.api.airportFees.isPatchLoading

export const selectIsDeleteAirportFeeLoading = (store: RootState) =>
  store.api.airportFees.isDeleteLoading
