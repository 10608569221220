import { RootState } from '@app/store'

export const selectRequestsListLimit = (store: RootState) =>
  store.pages.requests.requestList.limit

export const selectRequestsListPage = (store: RootState) =>
  store.pages.requests.requestList.page

export const selectRequestsListFilters = (store: RootState) =>
  store.pages.requests.requestList.filters

export const selectRequestsListOrderBy = (store: RootState) =>
  store.pages.requests.requestList.orderBy

export const selectRequestsListData = (store: RootState) =>
  store.pages.requests.requestList.data

export const selectRequestsListTotal = (store: RootState) =>
  store.pages.requests.requestList.total

export const selectRequestsListIsLoading = (store: RootState) =>
  store.pages.requests.requestList.isLoading

export const selectRequestsListError = (store: RootState) =>
  store.pages.requests.requestList.error

export const selectRequestsListOrderDirection = (store: RootState) =>
  store.pages.requests.requestList.orderDirection

export const selectRequestsListIsReloading = (store: RootState) =>
  store.pages.requests.requestList.isReloading

export const selectRequestsListIsPolling = (store: RootState) =>
  store.pages.requests.requestList.isPolling

export const selectOpenRequestId = (store: RootState) =>
  store.pages.requests.requestList.openRequestId

export const selectRequestPageVariant = (store: RootState) =>
  store.pages.requests.requestList.variant
