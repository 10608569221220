import React from 'react'
import styled from 'styled-components'

type LogoProps = Record<string, unknown>

const Logo = (props: LogoProps): JSX.Element => {
  return (
    <StyledSvg
      viewBox="0 0 65 65"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <path
          id="a"
          d="M48.72 34.51C51 51.26 47.58 65 47.58 65s-6.84-7.1-12.83-18.54c3.98-2.71 8.07-5.97 11.93-9.83.7-.7 1.38-1.41 2.04-2.12zm-4.39-.24C59.5 19.1 65 0 65 0S49.04 4.59 34.71 16.97C16.12 13.42 0 17.42 0 17.42s8.36 8.06 21.55 14.34c-8.29 12.03-11.5 23.19-11.5 23.19s19.11-5.5 34.28-20.68z"
        />
      </defs>
      <use fill="currentColor" overflow="visible" xlinkHref="#a" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  color: ${({ theme }) => theme.palette.primary.main};
`

export default Logo
