import { RootState } from '@app/store'

export const selectContactPersonsLimit = (store: RootState) =>
  store.pages.clientDetail.contactPersons.limit

export const selectContactPersonsPage = (store: RootState) =>
  store.pages.clientDetail.contactPersons.page

export const selectContactPersonsFilters = (store: RootState) =>
  store.pages.clientDetail.contactPersons.filters

export const selectContactPersonsOrderBy = (store: RootState) =>
  store.pages.clientDetail.contactPersons.orderBy

export const selectContactPersonsData = (store: RootState) =>
  store.pages.clientDetail.contactPersons.data

export const selectContactPersonsTotal = (store: RootState) =>
  store.pages.clientDetail.contactPersons.total

export const selectContactPersonsIsLoading = (store: RootState) =>
  store.pages.clientDetail.contactPersons.isLoading

export const selectContactPersonsIsReloading = (store: RootState) =>
  store.pages.clientDetail.contactPersons.isReloading

export const selectContactPersonsError = (store: RootState) =>
  store.pages.clientDetail.contactPersons.error

export const selectContactPersonsOrderDirection = (store: RootState) =>
  store.pages.clientDetail.contactPersons.orderDirection

export const selectIsDeleteContactPersonLoading = (store: RootState) =>
  store.pages.clientDetail.contactPersons.isDeleteContactPersonLoading

export const selectDeleteContactPersonError = (store: RootState) =>
  store.pages.clientDetail.contactPersons.deleteContactPersonError
