import { action } from 'typesafe-actions'

import { AircraftListActionTypes } from '@app/store/pages/fleet/aircraftList/aircraftList.constants'
import { AircraftListSortProperties } from '@app/store/pages/fleet/aircraftList/aircraftList.types'
import { AircraftListStore } from '@app/store/pages/fleet/aircraftList/aircraftList.reducer'

import { GetAircraftListFilters } from '@app/utils/api/types'

import * as DTOs from '@shared/dto'

export const getAircraftListDataFirstPageAction = () =>
  action(AircraftListActionTypes.GetAircraftListDataFirstPage)

export const getAircraftListDataNextPageAction = () =>
  action(AircraftListActionTypes.GetAircraftListDataNextPage)

export const getAircraftListSuccessAction = (
  response: DTOs.PaginatedList<DTOs.AircraftDetailDto>,
) => action(AircraftListActionTypes.GetAircraftListSuccess, response)

export const getAircraftListFailureAction = (error: unknown) =>
  action(AircraftListActionTypes.GetAircraftListFailure, error)

export const startSetAircraftListSortAction = (
  sort: AircraftListSortProperties,
) => action(AircraftListActionTypes.StartSetAircraftListSort, sort)

export const startSetAircraftListFiltersAction = (
  filters: Partial<GetAircraftListFilters>,
) => action(AircraftListActionTypes.StartSetAircraftListFilters, { filters })

export const setAircraftListParametersAction = (
  parameters: Partial<
    Pick<AircraftListStore, 'orderBy' | 'orderDirection' | 'filters'>
  >,
) => action(AircraftListActionTypes.SetAircraftListParameters, parameters)

export const deleteAircraftAction = (id: number) =>
  action(AircraftListActionTypes.DeleteAircraft, id)

export const deleteAircraftSuccessAction = (id: number) =>
  action(AircraftListActionTypes.DeleteAircraftSuccess, id)
