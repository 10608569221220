import { ActionType } from 'typesafe-actions'
import produce, { castDraft } from 'immer'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import {
  OfferProfitAndPrice,
  ScheduleDetailDto,
} from '@shared/dto/schedule.dto'
import { ScheduleActionTypes } from '@app/store/pages/schedule/scheduleList/schedule.constants'
import { ListStore } from '@app/store/types'
import { GetScheduleFilters } from '@app/utils/api/types'
import * as ScheduleActions from '@app/store/pages/schedule/scheduleList/schedule.actions'
import { AircraftDetailDto } from '@shared/dto/aircraft.dto'

import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_PAGE_LIMIT,
  DEFAULT_SCHEDULE_PAGE_LIMIT,
} from '@app/constants'

dayjs.extend(utc)

type ScheduleAction = ActionType<typeof ScheduleActions>

export interface ScheduleStore
  extends Omit<ListStore<ScheduleDetailDto, GetScheduleFilters>, 'data'> {
  schedule: ScheduleDetailDto[] | null
  aircraft: AircraftDetailDto[] | null
  finalPriceAndTotalProfit: OfferProfitAndPrice[] | null
}

const initialScheduleState: ScheduleStore = {
  error: null,
  isLoading: false,
  schedule: null,
  aircraft: null,
  total: null,
  finalPriceAndTotalProfit: null,
  page: 1,
  limit: DEFAULT_PAGE_LIMIT,
  orderBy: DEFAULT_ORDER_BY,
  orderDirection: DEFAULT_ORDER_DIRECTION,
  filters: {
    arrival_date_start: dayjs.utc().subtract(1, 'hour').toISOString(),
    departure_date_end: dayjs.utc().endOf('day').add(3, 'day').toISOString(),
  },
}

const ScheduleReducer = produce<ScheduleStore, [ScheduleAction]>(
  (state, action) => {
    switch (action.type) {
      case ScheduleActionTypes.GetScheduleDataFirstPage:
        state.isLoading = true
        state.schedule = null
        state.aircraft = null
        state.error = null
        state.total = 0
        state.limit = DEFAULT_PAGE_LIMIT
        state.page = 1

        break

      case ScheduleActionTypes.GetScheduleSuccess:
        // @see https://immerjs.github.io/immer/typescript/#cast-utilities
        state.schedule ??= []
        state.schedule.push(...castDraft(action.payload.schedule))
        state.aircraft = action.payload.aircraft
        state.finalPriceAndTotalProfit = action.payload.profit_and_price
        state.isLoading = false
        state.error = null
        state.total = action.payload.total
        state.page = action.payload.page

        break

      case ScheduleActionTypes.GetScheduleFailure:
        state.isLoading = false
        state.error = action.payload

        break

      case ScheduleActionTypes.GetScheduleDataNextPage:
        state.isLoading = true

        break

      case ScheduleActionTypes.SetScheduleParameters:
        if (action.payload.orderBy) {
          state.orderBy = action.payload.orderBy
        }

        if (action.payload.orderDirection) {
          state.orderDirection = action.payload.orderDirection
        }

        if (action.payload.filters) {
          state.filters = { ...state.filters, ...action.payload.filters }
        }

        break

      case ScheduleActionTypes.SetScheduleListData:
        state.schedule = []
        state.schedule.push(...castDraft(action.payload.schedule))
        state.aircraft = action.payload.aircraft
        state.finalPriceAndTotalProfit = action.payload.profit_and_price

        state.isLoading = false
        state.error = null
        state.total = action.payload.total
        state.page = action.payload.page

        break

      case ScheduleActionTypes.GetAllScheduleData:
        state.isLoading = true
        state.schedule = null
        state.aircraft = null
        state.error = null
        state.total = 0
        state.limit = DEFAULT_SCHEDULE_PAGE_LIMIT
        state.page = 1

        break

      default:
        return state
    }
  },
  initialScheduleState,
)

export default ScheduleReducer
