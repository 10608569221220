import { RootState } from '@app/store'

export const selectContactPersonsListLimit = (store: RootState) =>
  store.pages.clients.contactPersonsList.limit

export const selectContactPersonsListPage = (store: RootState) =>
  store.pages.clients.contactPersonsList.page

export const selectContactPersonsListFilters = (store: RootState) =>
  store.pages.clients.contactPersonsList.filters

export const selectContactPersonsListOrderBy = (store: RootState) =>
  store.pages.clients.contactPersonsList.orderBy

export const selectContactPersonsListData = (store: RootState) =>
  store.pages.clients.contactPersonsList.data

export const selectContactPersonsListTotal = (store: RootState) =>
  store.pages.clients.contactPersonsList.total

export const selectContactPersonsListIsLoading = (store: RootState) =>
  store.pages.clients.contactPersonsList.isLoading

export const selectContactPersonsListIsReloading = (store: RootState) =>
  store.pages.clients.contactPersonsList.isReloading

export const selectContactPersonsListError = (store: RootState) =>
  store.pages.clients.contactPersonsList.error

export const selectContactPersonsListOrderDirection = (store: RootState) =>
  store.pages.clients.contactPersonsList.orderDirection
