export enum ContactPersonsActionTypes {
  PostContactPerson = '[api] [contactPersons] [PostContactPerson]',
  PostContactPersonSuccess = '[api] [contactPersons] [PostContactPersonSuccess]',
  PostContactPersonFailure = '[api] [contactPersons] [PostContactPersonFailure]',
  CancelPostContactPerson = '[api] [contactPersons] [CancelPostContactPerson]',

  PatchContactPerson = '[pages] [contactPersons] [PatchContactPerson]',
  PatchContactPersonSuccess = '[pages] [contactPersons] [PatchContactPersonSuccess]',
  PatchContactPersonFailure = '[pages] [contactPersons] [PatchContactPersonFailure]',
}
