import React from 'react'
import { setAutoFreeze } from 'immer'
import { WrapPageElementBrowserArgs } from 'gatsby'
import { Provider as ReduxProvider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistGate } from 'redux-persist/integration/react'

import { LocalizationProvider } from '@material-ui/pickers'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import DayJsUtils from '@material-ui/pickers/adapter/dayjs'
import CssBaseline from '@material-ui/core/CssBaseline'

import GlobalStyleProvider from '@app/components/providers/GlobalStyleProvider'
import NotificationsProvider from '@app/components/providers/NotificationsProvider'
import ConfirmationDialogProvider from '@app/components/providers/ConfirmationDialogProvider'
import LoadingContainer from '@app/components/molecules/LoadingContainer/LoadingContainer'
import GlobalErrorBoundary from '@app/components/molecules/GlobalErrorBoundary/GlobalErrorBoundary'
import store, { persistor } from '@app/store'
import i18n from '@app/utils/i18n'
import { theme } from '@app/theme'

setAutoFreeze(false)

const queryClient = new QueryClient()

const AppProvider = ({
  element,
  children,
}: WrapPageElementBrowserArgs): JSX.Element => {
  return (
    <I18nextProvider i18n={i18n}>
      <LocalizationProvider dateAdapter={DayJsUtils}>
        <ReduxProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <GlobalStyleProvider>
                  <PersistGate
                    persistor={persistor}
                    loading={<LoadingContainer />}
                  >
                    <GlobalErrorBoundary>
                      <SnackbarProvider
                        hideIconVariant
                        maxSnack={5}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <NotificationsProvider>
                          <ConfirmationDialogProvider>
                            {element ?? children}
                          </ConfirmationDialogProvider>
                        </NotificationsProvider>
                      </SnackbarProvider>
                    </GlobalErrorBoundary>
                    <CssBaseline />
                  </PersistGate>
                </GlobalStyleProvider>
              </ThemeProvider>
            </MuiThemeProvider>

            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ReduxProvider>
      </LocalizationProvider>
    </I18nextProvider>
  )
}

export default AppProvider
