import { action } from 'typesafe-actions'

import { AirportFeesActionTypes } from '@app/store/api/airportFees/airportFees.constants'
import { AirportFeesSortProperties } from '@app/store/api/airportFees/airportFees.types'
import { GetAirportFeesFilters } from '@app/utils/api/types'

import * as DTOs from '@shared/dto'

export const getAirportFeesDataFirstPageAction = () =>
  action(AirportFeesActionTypes.GetAirportFeesDataFirstPage)

export const getAirportFeesDataNextPageAction = () =>
  action(AirportFeesActionTypes.GetAirportFeesDataNextPage)

export const getAirportFeesSuccessAction = (
  response: DTOs.PaginatedList<DTOs.AirportFeeDto>,
) => action(AirportFeesActionTypes.GetAirportFeesSuccess, response)

export const getAirportFeesFailureAction = (error: unknown) =>
  action(AirportFeesActionTypes.GetAirportFeesFailure, error)

export const setAirportFeesSortAction = (sort: AirportFeesSortProperties) =>
  action(AirportFeesActionTypes.SetAirportFeesSort, sort)

export const setAirportFeesFiltersAction = (
  filters: Partial<GetAirportFeesFilters>,
  requestDebounceInMilliseconds = 0,
) =>
  action(AirportFeesActionTypes.SetAirportFeesFilters, {
    filters,
    requestDebounceInMilliseconds,
  })

export const resetAirportFeesFiltersAction = () =>
  action(AirportFeesActionTypes.ResetAirportFeesFilters)

export const postAirportFeeAction = (
  postAirportFeeDto: DTOs.CreateAirportFeeBody,
) => action(AirportFeesActionTypes.PostAirportFee, postAirportFeeDto)

export const postAirportFeeSuccessAction = (response: { id: number }) =>
  action(AirportFeesActionTypes.PostAirportFeeSuccess, response)

export const postAirportFeeFailureAction = (error: unknown) =>
  action(AirportFeesActionTypes.PostAirportFeeFailure, error)

export const cancelPostAirportFeeLoadingAction = () =>
  action(AirportFeesActionTypes.CancelPostAirportFeeLoading)

export const cancelDeleteAirportFeeLoadingAction = () =>
  action(AirportFeesActionTypes.CancelDeleteAirportFeeLoading)

export const patchAirportFeeAction = (
  id: number,
  patchAirportFeeDto: DTOs.UpdateAirportFeeBody,
) =>
  action(AirportFeesActionTypes.PatchAirportFee, {
    data: patchAirportFeeDto,
    id,
  })

export const patchAirportFeeSuccessAction = (
  updatedAirportFee: DTOs.AirportFeeDto,
) => action(AirportFeesActionTypes.PatchAirportFeeSuccess, updatedAirportFee)

export const patchAirportFeeFailureAction = (error: unknown) =>
  action(AirportFeesActionTypes.PatchAirportFeeFailure, error)

export const deleteAirportFeeAction = (id: number) =>
  action(AirportFeesActionTypes.DeleteAirportFee, id)

export const deleteAirportFeeSuccessAction = () =>
  action(AirportFeesActionTypes.DeleteAirportFeeSuccess)

export const deleteAirportFeeFailureAction = (error: unknown) =>
  action(AirportFeesActionTypes.DeleteAirportFeeFailure, error)

export const reloadAirportFeeListAction = () =>
  action(AirportFeesActionTypes.ReloadAirportFees)

export const setAirportFeesDataAction = (
  data: DTOs.PaginatedList<DTOs.AirportFeeDto>,
) => action(AirportFeesActionTypes.SetAirportFeesData, data)
