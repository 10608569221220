import { action } from 'typesafe-actions'

import { CreateAircraftActionTypes } from '@app/store/pages/createAircraft/createAircraft/createAircraft.constants'
import { PartialAircraftDto } from '@shared/dto/aircraft.dto'

export const storePartialAircraftData = (partialAircraft: PartialAircraftDto) =>
  action(CreateAircraftActionTypes.StorePartialAircraftData, partialAircraft)

export const postAircraftAction = () =>
  action(CreateAircraftActionTypes.PostAircraft)

export const postAircraftSuccessAction = () =>
  action(CreateAircraftActionTypes.PostAircraftSuccess)

export const postAircraftFailureAction = (error: unknown) =>
  action(CreateAircraftActionTypes.PostAircraftFailure, error)

export const clearCreateAircraftStore = () =>
  action(CreateAircraftActionTypes.ClearCreateAircraftStore)
