import createSagaMiddleware from 'redux-saga'
import { StateType } from 'typesafe-actions'
import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { all } from 'redux-saga/effects'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import aircraftListReducer from '@app/store/pages/fleet/aircraftList/aircraftList.reducer'
import requestListReducer from '@app/store/pages/requests/requestList/requestList.reducer'
import createRequestReducer from '@app/store/pages/requests/createRequest/createRequest.reducer'
import aircraftDetailReducer from '@app/store/pages/aircraftDetail/aircraftDetail/aircraftDetail.reducer'
import createAircraftReducer from '@app/store/pages/createAircraft/createAircraft/createAircraft.reducer'
import notificationsReducer from '@app/store/ui/notifications/notifications.reducer'
import requestDetailReducer from '@app/store/pages/requests/requestDetail/requestDetail.reducer'
import airportListReducer from '@app/store/pages/airports/airportList/airportList.reducer'
import airportDetailReducer from '@app/store/pages/airportDetail/airportDetail/airportDetail.reducer'
import myTeamListReducer from '@app/store/pages/myTeam/myTeamList/myTeamList.reducer'
import userInfoReducer from '@app/store/core/userInfo/userInfo.reducer'
import userOperatorsReducer from '@app/store/core/userOperators/userOperators.reducer'
import confirmationDialogReducer from '@app/store/ui/confirmationDialog/confirmationDialog.reducer'
import scheduleListReducer from '@app/store/pages/schedule/scheduleList/schedule.reducer'
import scheduleManagementReducer from '@app/store/pages/schedule/scheduleManagement/scheduleManagement.reducer'
import customRoutesReducer from '@app/store/api/customRoutes/customRoutes.reducer'
import airportFeesReducer from '@app/store/api/airportFees/airportFees.reducer'
import clientsListReducer from '@app/store/pages/clients/clientsList/clientsList.reducer'
import clientDetailReducer from '@app/store/pages/clientDetail/clientDetail/clientDetail.reducer'
import clientsApiReducer from '@app/store/api/clients/clients.reducer'
import contactPersonsReducer from '@app/store/pages/clientDetail/contactPersons/contactPersons.reducer'
import contactPersonsApiReducer from '@app/store/api/contactPersons/contactPersons.reducer'
import operatorSettingsReducer from '@app/store/pages/settings/operatorSettings/operatorSettings.reducer'
import companySettingsReducer from '@app/store/pages/settings/companySettings/companySettings.reducer'
import requestsReportsReducer from '@app/store/pages/reports/requestsReports/requestsReportsReducer'
import bookingsReportsReducer from '@app/store/pages/reports/bookingsReports/bookingsReportsReducer'
import invitationReducer from '@app/store/pages/myTeam/invitation/invitation.reducer'
import myTeamManagementReducer from '@app/store/pages/myTeam/myTeamManagement/myTeamManagement.reducer'
import userRegistrationReducer from '@app/store/pages/userRegistration/userRegistration/userRegistration.reducer'
import rebookingReducer from '@app/store/pages/requests/rebooking/rebooking.reducer'
import contactPersonsListReducer from '@app/store/pages/clients/contactPersonsList/contactPersonsList.reducer'

import watchAircraftListSaga from '@app/store/pages/fleet/aircraftList/aircraftList.sagas'
import watchRequestsListSaga from '@app/store/pages/requests/requestList/requestList.sagas'
import watchCreateRequestSaga from '@app/store/pages/requests/createRequest/createRequest.sagas'
import watchAircraftDetailSaga from '@app/store/pages/aircraftDetail/aircraftDetail/aircraftDetail.sagas'
import watchCreateAircraftSaga from '@app/store/pages/createAircraft/createAircraft/createAircraft.sagas'
import watchOfferDetailSaga from '@app/store/pages/requests/requestDetail/requestDetail.sagas'
import watchAirportListSaga from '@app/store/pages/airports/airportList/airportList.sagas'
import watchAirportDetailSaga from '@app/store/pages/airportDetail/airportDetail/airportDetail.sagas'
import watchMyTeamListSaga from '@app/store/pages/myTeam/myTeamList/myTeamList.sagas'
import watchUserInfoSaga from '@app/store/core/userInfo/userInfo.sagas'
import watchUserOperatorsSaga from '@app/store/core/userOperators/userOperators.sagas'
import watchAuthSaga from '@app/store/core/auth/auth.sagas'
import watchScheduleSaga from '@app/store/pages/schedule/scheduleList/schedule.sagas'
import watchScheduleManagementSaga from '@app/store/pages/schedule/scheduleManagement/scheduleManagement.sagas'
import watchCustomRoutesSaga from '@app/store/api/customRoutes/customRoutes.sagas'
import watchAirportFeesSaga from '@app/store/api/airportFees/airportFees.sagas'
import watchOperatorSettingsSaga from '@app/store/pages/settings/operatorSettings/operatorSettings.sagas'
import watchClientsSaga from '@app/store/pages/clients/clientsList/clientsList.sagas'
import watchClientDetailSaga from '@app/store/pages/clientDetail/clientDetail/clientDetail.sagas'
import watchClientsApiSaga from '@app/store/api/clients/clients.sagas'
import watchContactPersonsSaga from '@app/store/pages/clientDetail/contactPersons/contactPersons.sagas'
import watchContactPersonsApiSaga from '@app/store/api/contactPersons/contactPersons.sagas'
import watchCompanySettingsSaga from '@app/store/pages/settings/companySettings/operatorSettings.sagas'
import watchRequestsReportsSaga from '@app/store/pages/reports/requestsReports/requestsReports.sagas'
import watchBookingsReportsSaga from '@app/store/pages/reports/bookingsReports/bookingsReports.sagas'
import watchInvitationSaga from '@app/store/pages/myTeam/invitation/invitation.sagas'
import watchMyTeamManagementSaga from '@app/store/pages/myTeam/myTeamManagement/myTeamManagement.sagas'
import watchUserRegistrationSaga from '@app/store/pages/userRegistration/userRegistration/userRegistration.sagas'
import watchRequestsRebookingSaga from '@app/store/pages/requests/rebooking/rebooking.sagas'
import watchContactPersonsListSaga from '@app/store/pages/clients/contactPersonsList/contactPersonsList.sagas'

import { deferredMiddleware } from '@app/store/middlewares/deferred.middleware'
import AirportNotesReducer from '@app/store/api/airportNotes/airportNotes.reduces'

const fleetReducer = combineReducers({
  aircraftList: aircraftListReducer,
})

const aircraftDetailPageReducer = combineReducers({
  aircraftDetail: aircraftDetailReducer,
})

const createAircraftPageReducer = combineReducers({
  createAircraft: createAircraftReducer,
})

const requestsReducer = combineReducers({
  requestList: requestListReducer,
  createRequest: createRequestReducer,
  requestDetail: requestDetailReducer,
  rebooking: rebookingReducer,
})

const airportsReducer = combineReducers({
  airportList: airportListReducer,
})

const airportDetailPageReducer = combineReducers({
  airportDetail: airportDetailReducer,
})

const myTeamReducer = combineReducers({
  myTeamList: myTeamListReducer,
  invitation: invitationReducer,
  myTeamManagement: myTeamManagementReducer,
})

const schedulePageReducer = combineReducers({
  scheduleList: scheduleListReducer,
  scheduleManagement: scheduleManagementReducer,
})

const apiReducer = combineReducers({
  airportFees: airportFeesReducer,
  clients: clientsApiReducer,
  contactPersons: contactPersonsApiReducer,
  customRoutes: customRoutesReducer,
  aiportNotes: AirportNotesReducer,
})

const settingsReducer = combineReducers({
  operatorSettings: operatorSettingsReducer,
  companySettings: companySettingsReducer,
})

const clientsReducer = combineReducers({
  clientsList: clientsListReducer,
  contactPersonsList: contactPersonsListReducer,
})

const clientDetailPageReducer = combineReducers({
  clientDetail: clientDetailReducer,
  contactPersons: contactPersonsReducer,
})

const reportsReducer = combineReducers({
  bookingsReports: bookingsReportsReducer,
  requestsReports: requestsReportsReducer,
})

const userRegistrationPageReducer = combineReducers({
  userRegistration: userRegistrationReducer,
})

const pagesReducer = combineReducers({
  aircraftDetail: aircraftDetailPageReducer,
  airportDetail: airportDetailPageReducer,
  airports: airportsReducer,
  clientDetail: clientDetailPageReducer,
  clients: clientsReducer,
  createAircraft: createAircraftPageReducer,
  fleet: fleetReducer,
  myTeam: myTeamReducer,
  reports: reportsReducer,
  requests: requestsReducer,
  schedule: schedulePageReducer,
  settings: settingsReducer,
  userRegistration: userRegistrationPageReducer,
})

const uiReducer = combineReducers({
  notifications: notificationsReducer,
  confirmationDialog: confirmationDialogReducer,
})

const coreReducer = combineReducers({
  userInfo: userInfoReducer,
  userOperators: userOperatorsReducer,
})

const corePersistConfig = {
  key: 'core',
  storage,
  whiteList: ['userInfo', 'userOperators'],
}

const rootReducer = combineReducers({
  api: apiReducer,
  core: persistReducer(corePersistConfig, coreReducer),
  pages: pagesReducer,
  ui: uiReducer,
})

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(deferredMiddleware, sagaMiddleware)),
)

export type RootStore = StateType<typeof store>

export type RootState = StateType<typeof rootReducer>

const sagas = [
  watchContactPersonsListSaga(),
  watchAircraftListSaga(),
  watchRequestsListSaga(),
  watchCreateRequestSaga(),
  watchOfferDetailSaga(),
  watchAircraftDetailSaga(),
  watchCreateAircraftSaga(),
  watchAirportListSaga(),
  watchAirportDetailSaga(),
  watchMyTeamListSaga(),
  watchUserInfoSaga(),
  watchInvitationSaga(),
  watchUserOperatorsSaga(),
  watchAuthSaga(),
  watchScheduleSaga(),
  watchScheduleManagementSaga(),
  watchCustomRoutesSaga(),
  watchAirportFeesSaga(),
  watchOperatorSettingsSaga(),
  watchClientsSaga(),
  watchClientDetailSaga(),
  watchClientsApiSaga(),
  watchContactPersonsSaga(),
  watchContactPersonsApiSaga(),
  watchCompanySettingsSaga(),
  watchRequestsReportsSaga(),
  watchBookingsReportsSaga(),
  watchMyTeamManagementSaga(),
  watchUserRegistrationSaga(),
  watchRequestsRebookingSaga(),
]

function* rootSaga(): Generator {
  yield all(sagas)
}

sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export default store
