import { ActionType } from 'typesafe-actions'
import produce from 'immer'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import * as OperatorSettingsActions from '@app/store/pages/settings/operatorSettings/operatorSettings.actions'
import { OperatorSettingsActionTypes } from '@app/store/pages/settings/operatorSettings/operatorSettings.constants'

dayjs.extend(utc)

type OperatorSettingsAction = ActionType<typeof OperatorSettingsActions>

export interface OperatorSettingsStore {
  isPatchOperatorLoading: boolean
  patchOperatorError: unknown

  isPostOperatorLoading: boolean
  postOperatorError: unknown
}

const initialOperatorSettingsState: OperatorSettingsStore = {
  patchOperatorError: null,
  isPatchOperatorLoading: false,

  isPostOperatorLoading: false,
  postOperatorError: null,
}

const OperatorSettingsReducer = produce<
  OperatorSettingsStore,
  [OperatorSettingsAction]
>((state, action) => {
  switch (action.type) {
    case OperatorSettingsActionTypes.PatchOperator:
      state.isPatchOperatorLoading = true
      state.patchOperatorError = null

      break

    case OperatorSettingsActionTypes.PatchOperatorSuccess:
      state.isPatchOperatorLoading = false

      break

    case OperatorSettingsActionTypes.PatchOperatorFailure:
      state.isPatchOperatorLoading = false
      state.patchOperatorError = action.payload.error

      break

    case OperatorSettingsActionTypes.PostOperator:
      state.isPostOperatorLoading = true
      state.postOperatorError = null

      break

    case OperatorSettingsActionTypes.PostOperatorSuccess:
      state.isPostOperatorLoading = false
      state.postOperatorError = null

      break

    case OperatorSettingsActionTypes.PostOperatorFailure:
      state.isPostOperatorLoading = false
      state.postOperatorError = action.payload.error

      break

    default:
      return state
  }
}, initialOperatorSettingsState)

export default OperatorSettingsReducer
