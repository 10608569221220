import { RootState } from '@app/store'

export const selectIsConfirmationDialogOpen = (store: RootState) =>
  store.ui.confirmationDialog.open

export const selectIsConfirmationDialogLoading = (store: RootState) =>
  store.ui.confirmationDialog.isLoading

export const selectConfirmationDialogProps = (store: RootState) =>
  store.ui.confirmationDialog.dialogProps
