import produce from 'immer'
import { ActionType } from 'typesafe-actions'

import * as confirmationDialog from '@app/store/ui/confirmationDialog/confirmationDialog.actions'
import { ConfirmationDialogProps } from '@app/store/ui/confirmationDialog/confirmationDialog.types'
import { ConfirmationDialogActionTypes } from '@app/store/ui/confirmationDialog/confirmationDialog.constants'

type ConfirmationDialogAction = ActionType<typeof confirmationDialog>

type ConfirmationDialogStore = {
  open: boolean
  isLoading: boolean
  dialogProps: ConfirmationDialogProps | null
}

const initialConfirmationDialogState: ConfirmationDialogStore = {
  open: false,
  isLoading: false,
  dialogProps: null,
}

const reducerConfirmationDialog = produce<
  ConfirmationDialogStore,
  [ConfirmationDialogAction]
>((state, action) => {
  switch (action.type) {
    case ConfirmationDialogActionTypes.OpenConfirmationDialog:
      state.open = true
      state.dialogProps = action.payload

      break

    case ConfirmationDialogActionTypes.SubmitConfirmationDialog:
      state.isLoading = true

      break

    case ConfirmationDialogActionTypes.CloseConfirmationDialog:
      state.open = false
      state.isLoading = false
      state.dialogProps = null

      break

    default:
      return state
  }
}, initialConfirmationDialogState)

export default reducerConfirmationDialog
