import { ActionType } from 'typesafe-actions'
import produce from 'immer'

import { MyTeamManagementActionTypes } from '@app/store/pages/myTeam/myTeamManagement/myTeamManagement.constants'
import * as myTeamManagementActions from '@app/store/pages/myTeam/myTeamManagement/myTeamManagement.actions'

type MyTeamManagementAction = ActionType<typeof myTeamManagementActions>

export type MyTeamManagementStore = {
  isPutUserStatusLoading: boolean
  putUserStatusError: unknown
}

const initialMyTeamManagementState: MyTeamManagementStore = {
  putUserStatusError: null,
  isPutUserStatusLoading: false,
}

const myTeamManagementReducer = produce<
  MyTeamManagementStore,
  [MyTeamManagementAction]
>((state, action) => {
  switch (action.type) {
    case MyTeamManagementActionTypes.PutUserStatus:
      state.isPutUserStatusLoading = true
      state.putUserStatusError = null

      break

    case MyTeamManagementActionTypes.PutUserStatusSuccess:
      state.isPutUserStatusLoading = false
      state.putUserStatusError = null

      break

    case MyTeamManagementActionTypes.PutUserStatusFailure:
      state.isPutUserStatusLoading = false
      state.putUserStatusError = action.payload.error

      break

    default:
      return state
  }
}, initialMyTeamManagementState)

export default myTeamManagementReducer
