export enum RequestsListActionTypes {
  ReloadRequestsList = '[pages] [requests] [ReloadRequestsList]',
  ReloadRequestsListSuccess = '[pages] [requests] [ReloadRequestsListSuccess]',

  ResetRequestList = '[pages] [requests] [ResetRequestList]',

  GetRequestsListDataFirstPage = '[pages] [requests] [GetRequestsListDataFirstPage]',
  GetRequestsListDataNextPage = '[pages] [requests] [GetRequestsListDataNextPage]',
  GetRequestsListSuccess = '[pages] [requests] [GetRequestsListSuccess]',
  GetRequestsListFailure = '[pages] [requests] [GetRequestsListFailure]',

  SetRequestsListSort = '[pages] [requests] [SetRequestsListSort]',
  SetRequestsListFilters = '[pages] [requests] [SetRequestsListFilters]',
  ResetRequestsListFilters = '[pages] [requests] [ResetRequestsListFilters]',

  StartRequestsListPolling = '[pages] [requests] [StartRequestsListPolling]',
  StopRequestsListPolling = '[pages] [requests] [StopRequestsListPolling]',

  SetOpenRequestId = '[pages] [requests] [SetOpenRequestId]',

  DeclineRequest = '[pages] [requests] [DeclineRequest]',
  DeclineRequestSuccess = '[pages] [requests] [DeclineRequestSuccess]',
  DeclineRequestFailure = '[pages] [requests] [DeclineRequestFailure]',

  SetRequestPageVariant = '[pages] [requests] [SetRequestPageVariant]',
}
