import axios, { AxiosResponse } from 'axios'
import { navigate } from 'gatsby'
import produce from 'immer'

import { LocalStorageKeys } from '@app/constants'

import {
  DocumentFileType,
  DocumentStatus,
  DocumentType,
  OfferStatuses,
  Routes,
  UserStatuses,
} from '@shared/enums'

import {
  InvitationDto,
  PatchMarketplaceExtensionDto,
  TeamUserDto,
  UpdatePasswordDto,
  UpdateUserDisplaySettingsDto,
  UpdateUserDto,
} from '@app/utils/api/types'

import { LegComputationRequest } from '@shared/interfaces/Computation'

import * as DTOs from '@shared/dto'

const GATSBY_BASE_API_URL = process.env.GATSBY_BASE_API_URL

const instance = axios.create({
  baseURL: GATSBY_BASE_API_URL,
})

enum ApiRoutes {
  Aircraft = 'aircraft',
  AirportFees = 'airport-fees',
  AirportNote = 'airport-note',
  Airports = 'airports',
  Airway = 'airway',
  Auth = 'auth',
  AviaCalculator = 'avia-calculator',
  AvoidedCountries = 'avoided-countries',
  Cabotage = 'cabotage',
  Chat = 'chat',
  Client = 'clients',
  Companies = 'companies',
  Computations = 'computations',
  ContactPersons = 'contact-persons',
  Countries = 'countries',
  CreateOffersFromComputation = 'create-offers-from-computation',
  Currencies = 'currencies',
  CustomRoutes = 'custom-routes',
  Documents = 'documents',
  FuelCosts = 'fuel-costs',
  Invitations = 'invitations',
  My = 'my',
  Offers = 'offers',
  Operators = 'operators',
  OvernightFees = 'overnight-fees',
  Reports = 'reports',
  Requests = 'requests',
  Schedule = 'schedule',
  Users = 'users',
  Warnings = 'warnings',
}

const PUBLIC_API_ENDPOINTS = [
  'auth/login',
  'users/registration/init',
  'users/registration/submit',
  'countries',
  'currencies',
]

const createAxiosRequestInterceptor = () => {
  return instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(LocalStorageKeys.AuthToken)

      if (!token && config.url && !PUBLIC_API_ENDPOINTS.includes(config.url)) {
        navigate(Routes.Login)

        return config
      }

      config.headers.Authorization = 'Bearer ' + token

      return config
    },
    (error) => Promise.reject(error),
  )
}

const createAxiosResponseInterceptor = () => {
  const responseInterceptor = instance.interceptors.response.use(
    (config) => config,
    async (error) => {
      if (
        error.response.status !== 401 ||
        error.config.url === `${ApiRoutes.Auth}/login`
      ) {
        return Promise.reject(error)
      }

      instance.interceptors.response.eject(responseInterceptor)

      try {
        const { data } = await instance.post(`${ApiRoutes.Auth}/refresh`)

        const { access_token } = data

        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + access_token

        localStorage.setItem(LocalStorageKeys.AuthToken, access_token)

        const originalRequestResult = await instance(error.config)

        return originalRequestResult
      } catch (error) {
        localStorage.removeItem(LocalStorageKeys.AuthToken)

        navigate(Routes.Login)
      } finally {
        createAxiosResponseInterceptor()
      }
    },
  )

  return responseInterceptor
}

createAxiosResponseInterceptor()
createAxiosRequestInterceptor()

type Response<Data = unknown> = Promise<AxiosResponse<Data>>

export const api = {
  // #region Auth
  login: (
    email: string,
    password: string,
    preferedOperatorId?: number,
  ): Response<{ access_token: string; operatorId: number }> => {
    return instance.post(`${ApiRoutes.Auth}/login`, {
      email,
      password,
      preferedOperatorId,
    })
  },

  logout: (): Response<void> => {
    return instance.post(`${ApiRoutes.Auth}/logout`)
  },

  requestPasswordChange: (
    params: DTOs.RequestResetPasswordDto,
  ): Response<void> => {
    return instance.post(`${ApiRoutes.Auth}/request-password-change`, params)
  },

  logoutFromAllSessions: (token: string): Response<void> => {
    return instance.post(`${ApiRoutes.Auth}/logout-from-all-sessions`, {
      token,
    })
  },

  resetPassword: (params: DTOs.ResetPasswordDto): Response<void> => {
    return instance.post(`${ApiRoutes.Auth}/reset-password`, params)
  },

  validateToken: (params: { token: string }): Response<void> => {
    return instance.get(`${ApiRoutes.Auth}/validate-token`, { params })
  },

  switchOperator: (operatorId: number): Response<{ access_token: string }> => {
    return instance.post(`${ApiRoutes.Auth}/switch-operator`, { operatorId })
  },
  // #endregion

  // #region Aircraft
  listAircraft: (
    params: DTOs.AircraftSearchParams,
  ): Response<DTOs.PaginatedList<DTOs.AircraftDetailDto>> => {
    return instance.get(ApiRoutes.Aircraft, { params })
  },

  exportAircraft: (params: DTOs.AircraftSearchParams): Response<Blob> => {
    return instance.get(`${ApiRoutes.Aircraft}/export`, {
      params,
      responseType: 'blob',
    })
  },

  getAircraftRegistrationCountry: (
    registrationCode: string,
  ): Response<DTOs.CountryDto> => {
    return instance.get(`${ApiRoutes.Aircraft}/country-of-registration`, {
      params: { registrationCode },
    })
  },

  getAircraft: (id: number): Response<DTOs.AircraftDetailDto> => {
    return instance.get(`${ApiRoutes.Aircraft}/${id}`)
  },

  listAircraftImages: (id: number): Response<{ id: number }[]> => {
    return instance.get(`${ApiRoutes.Aircraft}/${id}/pictures`)
  },

  getAircraftImage: (aircraftId: number, id: number): Response<Blob> => {
    return instance.get(`${ApiRoutes.Aircraft}/${aircraftId}/picture/${id}`, {
      responseType: 'blob',
    })
  },

  uploadAircraftImages: (
    aircraftId: number,
    files: FileList,
    max: number = 6,
  ): Response<{ status: 'ok' | 'nok' }> => {
    const formData = new FormData()
    const allowed = Math.min(files.length, max)
    for (let i = 0; i < allowed; i++) {
      formData.append('files', files.item(i)!)
    }
    return instance.patch(
      `${ApiRoutes.Aircraft}/${aircraftId}/pictures`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
  },

  deleteAircraftImages: (
    aircraftId: number,
    images: number[],
  ): Response<{ status: 'ok' | 'nok' }> => {
    return instance.delete(`${ApiRoutes.Aircraft}/${aircraftId}/pictures`, {
      data: {
        pictureIds: images,
      },
    })
  },

  createAircraft: (
    params: DTOs.AircraftDetailDto,
  ): Response<DTOs.AircraftDetailDto> => {
    return instance.post(ApiRoutes.Aircraft, params)
  },

  updateAircraft: (
    id: number,
    params: DTOs.PartialAircraftDto,
  ): Response<DTOs.AircraftDetailDto> => {
    return instance.patch(`${ApiRoutes.Aircraft}/${id}`, params)
  },

  copyAircraft: (
    id: number,
    params: DTOs.CopyAircraftRequestBody,
  ): Response<{ id: number }> => {
    return instance.post(ApiRoutes.Aircraft + `/${id}/copy`, params)
  },

  deleteAircraft: (id: number): Response => {
    return instance.delete(`${ApiRoutes.Aircraft}/${id}`)
  },

  // #endregion

  // #region AirportFees
  listAirportFees: (
    params: DTOs.GetAirportFeesQuery,
  ): Response<DTOs.PaginatedList<DTOs.AirportFeeDto>> => {
    return instance.get(ApiRoutes.AirportFees, { params })
  },

  exportAirportFees: (params: DTOs.GetAirportFeesQuery): Response<Blob> => {
    return instance.get(`${ApiRoutes.AirportFees}/export`, {
      params,
      responseType: 'blob',
    })
  },

  createAirportFee: (
    body: DTOs.CreateAirportFeeBody,
  ): Response<DTOs.CreateResourceDto> => {
    return instance.post(ApiRoutes.AirportFees, body)
  },

  importAirportFees: (
    body: DTOs.ImportAirportFeesBody,
    file: File,
  ): Response<DTOs.CreateResourceDto[]> => {
    const formData = new FormData()
    formData.append('file', file)
    body.aircraft_id &&
      formData.append('aircraft_id', body.aircraft_id.toString())
    return instance.post(`${ApiRoutes.AirportFees}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  updateAirportFee: (
    id: number,
    data: DTOs.UpdateAirportFeeBody,
  ): Response<DTOs.AirportFeeDto> => {
    return instance.patch(`${ApiRoutes.AirportFees}/${id}`, data)
  },

  deleteAirportFee: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.AirportFees}/${id}`)
  },
  //#endregion

  // #region AirportNote
  createOrUpdateAirportNote: (data: DTOs.CreateAirportNoteBody) => {
    return instance.patch(ApiRoutes.AirportNote, data)
  },

  getAirportNotes: (): Response<DTOs.AirportNote[]> => {
    return instance.get(ApiRoutes.AirportNote)
  },
  // #endregion

  // #region Airports
  listAirports: (
    params: DTOs.GetAirportsQuery,
  ): Response<DTOs.PaginatedList<DTOs.AirportDetailDto>> => {
    return instance.get(`${ApiRoutes.Airports}`, { params })
  },

  getMapAirports: (
    params: DTOs.GetAirportsMapQuery,
  ): Response<DTOs.AirportMapDto[]> => {
    return instance.get(`${ApiRoutes.Airports}/map`, { params })
  },

  exportAirports: (params: DTOs.GetAirportsQuery): Response<Blob> => {
    return instance.get(`${ApiRoutes.Airports}/export`, {
      responseType: 'blob',
      params,
    })
  },

  getTechStopAirports: (params: {
    tech_stops: string
  }): Response<DTOs.AirportDetailDto[]> => {
    return instance.get(`${ApiRoutes.Airports}/techstops`, { params })
  },

  getAirport: (id: number): Response<DTOs.AirportDetailDto> => {
    return instance.get(`${ApiRoutes.Airports}/${id}`)
  },
  //#endregion

  // #region Airway
  getAirway(id: number): Response<DTOs.AirwayDto> {
    return instance.get(`${ApiRoutes.Airway}/${id}`)
  },

  saveAirway(
    body: DTOs.SaveAirwayRequestInput,
  ): Response<DTOs.SaveAirwayRequestOutput> {
    return instance.post(`${ApiRoutes.Airway}`, body)
  },

  getMultipleAirways(ids: number[]): Response<DTOs.AirwayDto[]> {
    return ids.length > 0
      ? instance.get(`${ApiRoutes.Airway}`, {
          params: { ids: ids.join(',') },
        })
      : Promise.resolve({
          data: [],
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {},
        })
  },
  // #endregion

  // #region AviaCalculator
  generateAviaPageCalculation(
    body: DTOs.AviaFlightTimeRequestInput,
  ): Response<DTOs.AviaCalculationsResponseDto> {
    return instance.post(`${ApiRoutes.AviaCalculator}`, body)
  },

  // #endregion

  // #region AvoidedCountries
  getAvoidedCountries(aircraftId?: number): Response<DTOs.AvoidedCountry[]> {
    return instance.get(`${ApiRoutes.AvoidedCountries}`, {
      params: { aircraftId },
    })
  },

  createAvoidedCountry(body: DTOs.CreateAvoidedCountryInput): Response<void> {
    return instance.post(`${ApiRoutes.AvoidedCountries}`, body)
  },

  updateAvoidedCountry(
    body: DTOs.UpdateAvoidedCountryInput,
    id: string,
  ): Response<void> {
    return instance.patch(`${ApiRoutes.AvoidedCountries}/${id}`, body)
  },

  deleteAvoidedCountry(id: string): Response<void> {
    return instance.delete(`${ApiRoutes.AvoidedCountries}/${id}`)
  },
  // #endregion

  // #region Cabotage
  listCabotages(
    params: DTOs.CabotageSearchParams,
  ): Response<DTOs.PaginatedList<DTOs.Cabotage>> {
    return instance.get(`${ApiRoutes.Cabotage}`, {
      params,
    })
  },

  createCabotage(body: DTOs.CreateCabotage): Response<DTOs.Cabotage[]> {
    return instance.post(`${ApiRoutes.Cabotage}`, body)
  },

  deleteCabotage(id: number): Response<void> {
    return instance.delete(`${ApiRoutes.Cabotage}/${id}`)
  },
  // #endregion

  // #region Chat
  getNewMessages: (
    newerThanSeconds: number = 60,
  ): Response<DTOs.ChatMessageDto[]> => {
    return instance.get(`${ApiRoutes.Chat}/newMessages`, {
      params: { newerThanSeconds },
    })
  },

  getUnreadMessagesCount: (): Response<number> => {
    return instance.get(`${ApiRoutes.Chat}/unreadMessagesCount`)
  },

  markMessagesAsRead: (
    requests: DTOs.MarkMessagesAsReadDto,
  ): Response<{ success: boolean; message?: string }> => {
    return instance.put(`${ApiRoutes.Chat}/markMessagesAsRead`, requests)
  },

  getChat: (
    params: DTOs.ChatSearchParams,
  ): Response<DTOs.PaginatedList<DTOs.ChatMessageDto>> => {
    return instance.get(`${ApiRoutes.Chat}`, {
      params,
    })
  },

  createChatMessage: (
    body: DTOs.CreateChatMessageDto,
  ): Response<{ id: number }> => {
    return instance.post(`${ApiRoutes.Chat}`, body)
  },
  // #endregion

  // #region Clients
  exportClients: (params: DTOs.GetClientsQuery): Response<Blob> => {
    return instance.get(`${ApiRoutes.Client}/export`, {
      params,
      responseType: 'blob',
    })
  },

  listClients: (
    params: DTOs.GetClientsQuery,
  ): Response<DTOs.PaginatedList<DTOs.ClientDetailDto>> => {
    return instance.get(ApiRoutes.Client, { params })
  },

  getClient: (id: number): Response<DTOs.ClientDetailDto> => {
    return instance.get(`${ApiRoutes.Client}/${id}`)
  },

  createClient: (
    client: DTOs.CreateClientDto,
  ): Response<DTOs.ClientDetailDto> => {
    return instance.post(ApiRoutes.Client, client)
  },

  updateClient: (
    id: number,
    data: DTOs.UpdateClientDto,
  ): Response<DTOs.ClientDetailDto> => {
    return instance.patch(`${ApiRoutes.Client}/${id}`, data)
  },

  deleteClient: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Client}/${id}`)
  },
  // #endregion

  // #region Companies
  getCompany: (id: number): Response<DTOs.CompanyDto> => {
    return instance.get(`${ApiRoutes.Companies}/${id}`)
  },

  updateCompany: (
    id: number,
    patchCompanyDto: DTOs.UpdateCompanyDto,
  ): Response<DTOs.CompanyDto> => {
    return instance.patch(`${ApiRoutes.Companies}/${id}`, patchCompanyDto)
  },
  // #endregion

  // #region Computations
  createComputation: (
    params: DTOs.OfferComputationInputDto,
  ): Response<DTOs.CreateResourceDto> => {
    return instance.post(ApiRoutes.Computations, params)
  },

  getComputation: (id: number): Response<DTOs.ComputationResultDto> => {
    return instance.get(`${ApiRoutes.Computations}/${id}`)
  },
  // #endregion

  // #region ContacePersons
  listContactPersons: (
    params: DTOs.GetCPQuery,
  ): Response<DTOs.PaginatedList<DTOs.ContactPersonDetailDto>> => {
    return instance.get(ApiRoutes.ContactPersons, { params })
  },

  createContactPerson: (
    contactPerson: DTOs.CreateContactPersonDto,
  ): Response<DTOs.ContactPersonDetailDto> => {
    return instance.post(ApiRoutes.ContactPersons, contactPerson)
  },

  updateContactPerson: (
    id: number,
    data: DTOs.UpdateContactPersonDto,
  ): Response<DTOs.ContactPersonDetailDto> => {
    return instance.patch(`${ApiRoutes.ContactPersons}/${id}`, data)
  },

  deleteContactPerson: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.ContactPersons}/${id}`)
  },

  exportContactPersons: (
    params: Omit<DTOs.GetCPQuery, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.ContactPersons}/export`, {
      params,
      responseType: 'blob',
    })
  },
  // #endregion

  // #region Countries
  listCountries: (
    params: DTOs.CountrySearchParams,
  ): Response<DTOs.PaginatedList<DTOs.CountryDto>> => {
    return instance.get(ApiRoutes.Countries, { params })
  },

  getCountryByCode: (code: string): Response<DTOs.CountryDto> => {
    return instance.get(`${ApiRoutes.Countries}/code/${code}`)
  },
  // #endregion

  // #region CreateOffersFromComputation
  createOffersFromComputation: (
    params: DTOs.CreateOffersFromComputationDto,
  ): Response<DTOs.CreateOffersFromComputationResponseDto> => {
    return instance.post(ApiRoutes.CreateOffersFromComputation, params)
  },

  getOffer: (offerId: number): Response<DTOs.OfferDetailDto> => {
    return instance.get(`/offers/${offerId}`)
  },

  getOffers: (offerIds: number[]): Response<DTOs.OfferDetailDto[]> => {
    return offerIds.length > 0
      ? instance.get(`/offers/multiple/${offerIds.join(',')}`)
      : Promise.resolve({
          data: [] as DTOs.OfferDetailDto[],
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {},
        })
  },
  // #endregion

  // #region Currencies
  listCurrencies: (
    params: DTOs.CurrencySearchParams,
  ): Response<DTOs.PaginatedList<DTOs.CurrencyDto>> => {
    return instance.get(ApiRoutes.Currencies, { params })
  },
  // #endregion

  // #region CustomRoutes
  listCustomRoutes: (
    params: DTOs.CustomRouteSearchParams,
  ): Response<DTOs.PaginatedList<DTOs.CustomRouteDetailDto>> => {
    return instance.get(`${ApiRoutes.CustomRoutes}`, { params })
  },

  exportCustomRoutes: (
    params: Omit<DTOs.CustomRouteSearchParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.CustomRoutes}/export`, {
      params,
      responseType: 'blob',
    })
  },

  createCustomRoute: (
    body: DTOs.CreateCustomRouteDto,
  ): Response<{ id: number }> => {
    return instance.post(ApiRoutes.CustomRoutes, body)
  },

  updateCustomRoute: (
    id: number,
    body: DTOs.UpdateCustomRouteDto,
  ): Response<DTOs.CustomRouteDetailDto> => {
    return instance.patch(`${ApiRoutes.CustomRoutes}/${id}`, body)
  },

  deleteCustomRoute: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.CustomRoutes}/${id}`)
  },
  // #endregion

  // #region Documents
  generateOfferDocument(body: {
    offer_id: number
    type: DocumentType
    fileType: DocumentFileType
    user: DTOs.UserDto | null
  }): Response<{ id: number; status: DocumentStatus }> {
    return instance.post(`${ApiRoutes.Documents}/generate`, body)
  },

  getDocumentStatus(body: {
    id: number
  }): Response<{ status: DocumentStatus }> {
    return instance.get(`${ApiRoutes.Documents}/status`, {
      params: { document_id: body.id },
    })
  },

  downloadDocument(body: { id: number }): Response<Blob> {
    return instance.get(`${ApiRoutes.Documents}/download`, {
      responseType: 'blob',
      params: { document_id: body.id },
    })
  },
  // #endregion

  // #region FuelCosts
  listFuelCosts(
    query: DTOs.GetFuelCostsQuery,
  ): Response<DTOs.PaginatedList<DTOs.FuelCostDto>> {
    return instance.get(`${ApiRoutes.FuelCosts}`, { params: query })
  },

  importFuelCosts: (file: File): Response<DTOs.CreateResourceDto[]> => {
    const formData = new FormData()
    formData.append('file', file)
    return instance.post(`${ApiRoutes.FuelCosts}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },

  exportFuelCosts(query: DTOs.GetFuelCostsQuery): Response<Blob> {
    return instance.get(`${ApiRoutes.FuelCosts}/export`, { params: query })
  },

  createFuelCost(
    data: DTOs.CreateFuelCostBody,
  ): Response<DTOs.CreateResourceDto> {
    return instance.post(`${ApiRoutes.FuelCosts}`, data)
  },

  updateFuelCost(
    id: number,
    data: DTOs.UpdateFuelCostBody,
  ): Response<DTOs.FuelCostDto> {
    return instance.patch(`${ApiRoutes.FuelCosts}/${id}`, data)
  },

  deleteFuelCost(id: number): Response<undefined> {
    return instance.delete(`${ApiRoutes.FuelCosts}/${id}`)
  },
  // #endregion

  // #region Invitations
  createInvitation: (postInvitationDto: InvitationDto) => {
    return instance.post(`${ApiRoutes.Invitations}`, postInvitationDto)
  },

  postResendInvitation: (operatorId: number, userId: number) => {
    return instance.post(`${ApiRoutes.Invitations}/resend`, {
      operator_id: operatorId,
      user_id: userId,
    })
  },
  // #endregion

  // #region My
  getMyOperators: (): Response<DTOs.OperatorDto[]> => {
    return instance.get(`${ApiRoutes.My}/operators`)
  },

  putMyPassword: (params: UpdatePasswordDto): Response<void> => {
    return instance.put(`${ApiRoutes.My}/password`, params)
  },

  getMyUser: (): Response<DTOs.UserDto> => {
    return instance.get(`${ApiRoutes.My}/user`)
  },

  patchMyUser: (
    partialUser: UpdateUserDto | UpdateUserDisplaySettingsDto,
  ): Response<DTOs.UserDto> => {
    return instance.patch(`${ApiRoutes.My}/user`, partialUser)
  },

  updateOperatorSpecificUserData: (
    operatorId: number,
    data: DTOs.UpdateOperatorSpecificUserDataDto,
  ) => {
    return instance.patch(`${ApiRoutes.My}/user/${operatorId}`, data)
  },
  // #endregion

  // #region Offers
  cancelOffer: (id: number): Response<DTOs.OfferDetailDto> => {
    return instance.post(`${ApiRoutes.Offers}/${id}/cancel`)
  },

  reserveOffer: (offerId: number, date: Date): Response<void> => {
    return instance.post(`${ApiRoutes.Offers}/${offerId}/reserve`, {
      reservationDate: date,
    })
  },

  deleteOfferReservation: (offerId: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Offers}/${offerId}/reserve`)
  },

  rebookOffer: (offerId: number, data: LegComputationRequest[]) => {
    return instance.post(`${ApiRoutes.Offers}/${offerId}/rebook`, {
      requests: data,
    })
  },

  updateOfferStatus: (
    offerId: number,
    nextStatus: OfferStatuses,
    shouldSendEmail?: boolean,
    userNote?: string,
    cancellationFee?: number | null,
    shouldSendToAvinode?: boolean,
  ) => {
    type Payload = {
      status: OfferStatuses
      user_note?: string
      should_send_email?: boolean
      should_send_to_avinode?: boolean
      cancellation_fee?: number
    }

    const payload: Payload = {
      status: nextStatus,
      should_send_email: shouldSendEmail,
      should_send_to_avinode: shouldSendToAvinode,
      user_note: userNote,
    }

    return instance.put(
      `${ApiRoutes.Offers}/${offerId}/status`,
      produce(payload, (draft) => {
        if (Number.isFinite(cancellationFee)) {
          draft.cancellation_fee = cancellationFee as number
        }
      }),
    )
  },

  updateOffer: (
    offerId: number,
    // @todo Create dedicated Patch DTO
    partialOffer: Omit<Partial<DTOs.OfferDto>, 'legs'> & {
      legs: DTOs.BaseLegDetailDto[]
    },
  ): Response<DTOs.OfferDto> => {
    return instance.patch(`${ApiRoutes.Offers}/${offerId}`, partialOffer)
  },

  deleteOffer: (offerId: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Offers}/${offerId}`)
  },
  // #endregion

  // #region Operators
  createOperator: (
    postOperatorDto: Partial<DTOs.OperatorDto>,
  ): Response<DTOs.OperatorDto> => {
    return instance.post(ApiRoutes.Operators, postOperatorDto)
  },

  updateOperator: (
    id: number,
    partialOperatorDto: Partial<DTOs.OperatorDto>,
  ): Response<DTOs.OperatorDto> => {
    return instance.patch(`${ApiRoutes.Operators}/${id}`, partialOperatorDto)
  },

  updateOperatorImage: (
    file: File,
    operatorId: number,
  ): Response<{ status: 'ok' | 'nok' }> => {
    const formData = new FormData()
    formData.append('file', file)
    return instance.patch(
      `${ApiRoutes.Operators}/${operatorId}/logo`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
  },

  getOperatorImage: (operatorId: number): Response<Blob> => {
    return instance.get(`${ApiRoutes.Operators}/${operatorId}/logo`, {
      responseType: 'blob',
    })
  },
  // #endregion

  // #region OvernightFees
  listOvernightFees(
    query: DTOs.GetOvernightFeeQuery,
  ): Response<DTOs.PaginatedList<DTOs.OvernightFeeDto>> {
    return instance.get(`${ApiRoutes.OvernightFees}`, { params: query })
  },

  exportOvernightFees(query: DTOs.GetOvernightFeeQuery): Response<Blob> {
    return instance.get(`${ApiRoutes.OvernightFees}/export`, { params: query })
  },

  createOvernightFee(
    data: DTOs.CreateOvernightFeeBody,
  ): Response<DTOs.CreateResourceDto> {
    return instance.post(`${ApiRoutes.OvernightFees}`, data)
  },

  updateOvernightFee(
    id: number,
    data: DTOs.UpdateOvernightFeeBody,
  ): Response<DTOs.OvernightFeeDto> {
    return instance.patch(`${ApiRoutes.OvernightFees}/${id}`, data)
  },

  deleteOvernightFee(id: number): Response<undefined> {
    return instance.delete(`${ApiRoutes.OvernightFees}/${id}`)
  },
  // #endregion

  // #region Reports
  getRequestReports: (
    params: DTOs.ReportsSearchParams,
  ): Response<DTOs.RequestReportsDto> => {
    return instance.get(`${ApiRoutes.Reports}/requests`, { params })
  },

  getBookingReports: (
    params: DTOs.ReportsSearchParams,
  ): Response<DTOs.BookingReportsDto> => {
    return instance.get(`${ApiRoutes.Reports}/bookings`, { params })
  },

  getAircraftReport(
    params: DTOs.ReportsSearchParams,
  ): Response<DTOs.AircraftReportsDto[]> {
    return instance.get(`${ApiRoutes.Reports}/aircraft`, { params })
  },
  // #endregion

  // #region Requests
  listRequests: (
    params: DTOs.RequestSearchParams,
  ): Response<DTOs.PaginatedList<DTOs.PartialRequestDto>> => {
    return instance.get(ApiRoutes.Requests, { params })
  },

  exportRequests: (
    params: Omit<DTOs.RequestSearchParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.Requests}/export`, {
      params,
      responseType: 'blob',
    })
  },

  bulkDeclineRequests: (params: number[]): Response<void> => {
    return instance.post(`${ApiRoutes.Requests}/bulk/decline`, { ids: params })
  },

  bulkQuoteRequests: (params: number[]): Response<void> => {
    return instance.post(`${ApiRoutes.Requests}/bulk/quote`, { ids: params })
  },

  getRequest: (id: number): Response<DTOs.RequestDetailDto> => {
    return instance.get(`${ApiRoutes.Requests}/${id}`)
  },

  updateRequest: (requestId: number, params: DTOs.UpdateRequestDto) => {
    return instance.patch(`${ApiRoutes.Requests}/${requestId}`, params)
  },

  addRequestNote: (
    params: DTOs.CreateRequestNoteBody,
  ): Response<DTOs.RequestNoteDto> => {
    return instance.patch(
      [ApiRoutes.Requests, params.request_id, 'add-note'].join('/'),
      params,
    )
  },

  declineRequest: (requestId: number): Response<void> => {
    return instance.post(`${ApiRoutes.Requests}/${requestId}/decline`)
  },

  postMarkChatMessageAsRead: ({
    requestId,
    latestMessageId,
  }: {
    requestId: number
    latestMessageId: number
  }): Response => {
    return instance.post(
      `${ApiRoutes.Requests}/${requestId}/chat/mark-as-read`,
      { latest_read_chat_message_id: latestMessageId },
    )
  },

  getSimilarRequests: (
    requestId: number,
  ): Response<DTOs.RequestDetailDto[]> => {
    return instance.get(`${ApiRoutes.Requests}/${requestId}/similar`)
  },
  // #endregion

  // #region Schedule
  getScheduleList: (
    params: DTOs.ScheduleSearchParams,
  ): Response<DTOs.PaginatedScheduleListDto> => {
    return instance.get(ApiRoutes.Schedule, { params })
  },

  getScheduleListCsv: (
    params: Omit<DTOs.ScheduleSearchParams, 'limit' | 'page'>,
  ): Response<Blob> => {
    return instance.get(`${ApiRoutes.Schedule}/export`, {
      params,
      responseType: 'blob',
    })
  },

  createOutage: (params: DTOs.CreateOutageDto): Response<{ id: number }> => {
    return instance.post(`${ApiRoutes.Schedule}/outage`, params)
  },

  updateOutage: (
    id: number,
    params: DTOs.UpdateOutageDto,
  ): Response<DTOs.ScheduleDetailDto> => {
    return instance.patch(`${ApiRoutes.Schedule}/outage/${id}`, params)
  },

  deleteOutage: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Schedule}/outage/${id}`)
  },

  updateMarketplaceExtension: (
    id: number,
    params: PatchMarketplaceExtensionDto,
  ): Response<DTOs.ScheduleDetailDto> => {
    return instance.patch(
      `${ApiRoutes.Schedule}/${id}/marketplace-extension`,
      params,
    )
  },

  deleteMarketplaceExtension: (id: number): Response<void> => {
    return instance.delete(`${ApiRoutes.Schedule}/${id}/marketplace-extension`)
  },
  // #endregion

  // #region Users
  getUsers: (): Response<DTOs.UserDetailDto[]> => {
    return instance.get(`${ApiRoutes.Users}`)
  },

  exportUsers: (): Response<Blob> => {
    return instance.get(`${ApiRoutes.Users}/export`, {
      responseType: 'blob',
    })
  },

  registerUser: (
    id: number,
    partialUser: UpdateUserDto,
  ): Response<DTOs.UserDto> => {
    return instance.patch(`${ApiRoutes.Users}/${id}`, partialUser)
  },

  updateUserStatus: (
    userId: number,
    operatorId: number,
    status: UserStatuses,
  ): Response<void> => {
    return instance.put(
      `${ApiRoutes.Users}/${userId}/status`,
      { status },
      { params: { operator_id: operatorId } },
    )
  },

  getUser: (id: number): Response<DTOs.UserDetailDto[]> => {
    return instance.get(`${ApiRoutes.Users}/${id}`)
  },

  updateUser: (userId: number, updateTeamUser: TeamUserDto) => {
    return instance.patch(`${ApiRoutes.Users}/${userId}`, updateTeamUser)
  },

  initRegistration: (
    data: DTOs.InitRegistrationBodyDto,
  ): Response<DTOs.InitialRegistrationResponseDto> => {
    return instance.post(`${ApiRoutes.Users}/registration/init`, data)
  },

  submitRegistration: (
    data: DTOs.RegistrationBodyDto,
  ): Response<{ access_token: string }> => {
    return instance.post(`${ApiRoutes.Users}/registration/submit`, data)
  },
  // #endregion

  getReservedLegs: (
    opts: DTOs.LegSearchParams,
  ): Response<{ schedule: DTOs.ScheduleDetailDto[] }> => {
    return instance.get(`/leg`, {
      params: {
        reserved: true,
        ...opts,
      },
    })
  },

  // #region Warnings
  listWarnings(params: DTOs.WarningSearchParams): Response<DTOs.Warning[]> {
    return instance.get(`${ApiRoutes.Warnings}`, {
      params,
    })
  },

  createWarning(body: DTOs.CreateWarning): Response<void> {
    return instance.post(`${ApiRoutes.Warnings}`, body)
  },

  updateWarning(id: number, body: DTOs.UpdateWarning): Response<void> {
    return instance.patch(`${ApiRoutes.Warnings}/${id}`, body)
  },

  deleteWarning(id: number): Response<void> {
    return instance.delete(`${ApiRoutes.Warnings}/${id}`)
  },
  // #endregion
}
