import { createTheme } from '@material-ui/core/styles'

export interface Theme {
  font: {
    primary: string
    secondary: string
  }
  text: {
    warning: string
    profit: string
    heading: {
      darker: string
      dark: string
      regular: string
    }
  }
  opacity: {
    background: number
  }
  toast: {
    success: string
    warning: string
    error: string
    info: string
  }
  colors: {
    purple: string
    orange: string
    green: string
    grey: string
    red: string
    blue: string
    lightBlue: string
    brown: string
    blueBorder: string
    whiteBlue: string
    yellow: string
    darkBlue: string
  }
}

export const theme = createTheme({
  font: {
    primary: "'Rubik', sans-serif",
    secondary: "'Open Sans', sans-serif",
  },
  palette: {
    primary: {
      main: '#0064fc',
    },
    secondary: {
      main: '#F4865E',
      dark: '#FC8B2A',
      light: '#FFD7C8',
    },
    grey: {
      100: '#F4F4F4',
      200: '#C6C6C6',
      300: '#A8A8A8',
      400: '#8D8D8D',
      500: '#6F6F6F',
      600: '#686868',
      700: '#525252',
      800: '#393939',
    },
    text: {
      primary: '#393939',
      secondary: '#686868',
    },
  },

  text: {
    warning: '#FF4E41',
    profit: '#00BE9D',
    heading: {
      darker: '#00215F',
      dark: '#262626',
      regular: '#00396F',
    },
  },

  opacity: {
    background: 0.25,
  },

  toast: {
    success: '#497D00',
    warning: '#D97300',
    error: '#A41800',
    info: '#003289',
  },

  colors: {
    purple: '#8A3EFF',
    orange: '#FC8B2A',
    green: '#487D00',
    grey: '#A8A8A8',
    red: '#D92300',
    blue: '#2B64FC',
    lightBlue: '#3388D9',
    whiteBlue: '#EFF3FF',
    brown: '#AB6366',
    blueBorder: '#D3E0FF',
    yellow: '#A78C00',
    darkBlue: '#0D2461',
  },
})
